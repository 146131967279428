<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="cinemar-2880px.webp">
        <template #ProjectHeadline>
            <h1>CinemAR</h1>
        </template>
        <template #Services>
            <p>Konzeption, Experience Design</p>
        </template>
        <template #Description>
            <p>Entwicklung eines Leitsystems mit Erlebnischarakter im CinemaxX Stuttgart, das die Kinobesucher:innen vom
                Betreten des
                Gebäudes bis zum Verlassen in die Filmwelt abtauchen lässt.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Filme erschaffen Welten, in denen fremde Geschichte erlebt und bewusst eine Auszeit vom Alltag
                    genommen werden kann. Dabei bieten Kinos einen Ort und den Rahmen, um sowohl durch Ton als auch Bild
                    ein ganz besonderes Filmerleben zu erschaffen. Doch das Kinoerlebnis ist optimierbar. Es gibt
                    allerlei Probleme während eines Kinobesuchs. Auch das Erlebnis selbst beschränkt sich bisher alleine
                    auf den Film, den die Besucher:innen im Kinosaal geboten bekommen.
                </p>
                <p>Neben der Optimierung des Leitsystems wird in diesem Projekt das Kinoerlebnis nachhaltig verbessert.
                    Oft entscheiden wir uns für einen Kinobesuch dann, wenn die Story oder das Thema besonders
                    interessant sind. Diese intrinsische Motivation, sich einen Film anzusehen und in die Story
                    einzutauchen, wird im Konzept aufgegriffen und intensiviert. Neben den klassischen Merkmalen des
                    Leitsystems soll es die Besucher in die Welt ihres Films einladen und mitreißen, das zugängliche
                    Leitsystem durch nahbare Elemente erweitern, flexible Wegleitungen anbieten und persönliche
                    Einblicke liefern.</p>
            </template>
        </text-module>

    </section>
    <image-big imageName="cinemar-mockup-uebersicht.webp"
        altText="3D Modellierung der AR-Lösung für das Leitsystem des CinemaxX im Projekt CinemAR"></image-big>
    <div class="dark" style="padding-bottom: 50px;">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Die Zweiteilung der Anforderungen an das Leitsystem ist eine der Besonderheiten, die aus dem
                        Projekt resultierten. Die eine Dimension, – eine pragmatische Optimierung des Leitsystems, –
                        stellt Optimierungsanforderungen hinsichtlich der Zuordnung von Film und Kinosaal. Denn welcher
                        Film wann und wo läuft, wird innerhalb des Kinogebäudes schwer erkenntlich gemacht. Außerdem
                        herrscht während der “Rush Hour” eine hohe Unübersichtlichkeit, was Laufwege betrifft. Dazu
                        kommt, dass Besucher:innen im geschlossenen Raum oft ein schlechtes Zeitgefühl haben.
                    </p>
                    <p>Zwischen all den Dingen, die es beim Laufen, Essen kaufen und pünktlich Erscheinen zu beachten
                        gibt, wird man also meistens erst im Moment des Filmbeginns erst richtig in Stimmung versetzt.
                        Hier deutet sich die zweite Dimension des Leitsystems an: Um Besuchende schon frühzeitig in die
                        Welt ihres Films eintauchen zu lassen, wird die Umgebung des Kinogebäudes mithilfe von
                        AR-Brillen zum Leben erweckt. Da sind Filmfiguren, die den Weg weisen, Filmkulissen, die mit der
                        unmittelbaren Umgebung verschmelzen, und kleine Akzente, die mich auf den bevorstehenden Film
                        einstimmen. Ermöglicht wird das AR-Konzept durch Brillen, da diese für Besuchende keine
                        exorbitante Umstellung zu herkömmlichen Kino-3-D-Brillen darstellen.</p>
                </template>
            </text-module>

        </section>
        <image-double imageNameOne="cinemar-mockup-visualisierung-1.webp"
            altTextOne="Ansicht der Augmented Reality Lösung CinemAR im Kinogebäude, bei der man durch eine Brille Harry Potter erkennt"
            imageNameTwo="cinemar-mockup-visualisierung-2.webp"
            altTextTwo="Ansicht der Augmented Reality Lösung CinemAR am Kino Snackstand, bei der man durch eine Brille einen essenden Ron Weasly und Kerzen an der Decke erkennt"></image-double>
        <div style="height: 100px;"></div>
        <section class="content-container">
            <text-module side="left">
                <template #copy-text>
                    <p>Das neue klassische Leitsystem ist jedoch auch ohne AR-Brille wirksam. Es besteht aus
                        Leuchtstreifen auf dem Boden, die die Besuchenden ohne Umwege zum Kinosaal führt. Die Leitlinien
                        starten an der Kino-Kartenausgabe und sind wie die jeweilige Leitlinie zum Kinosaal sowie die
                        AR-Brillen farblich codiert. Je näher man dem Ziel (Kinosaal oder Ausgang) kommt, desto
                        intensiver werden die Farben bzw. das Lichtsignal. Bestehende Displays werden mit notwendigen
                        Informationen angereichert, ebenso wie die klassische Beschilderung der Säle ohne Einsatz
                        besonderer Technik bestehen bleibt.</p>
                </template>
            </text-module>
            <image-big imageName="cinemar-styleguide.svg" data-aos="fade-up"
                altText="Styleguide des HdM-Projektes CinemAR"></image-big>
        </section>

        <div style="height: 50px;"></div>
    </div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <h3>Analyse des Status Quo von Nutzungskontext und UX</h3>
                <p>Direkt zu Beginn des Projektes wurde der typische Prozess im Kinogebäude durchlaufen und
                    dokumentiert, subjektive Wahrnehmungen festgehalten und Schwachstellen des aktuellen Leitsystems
                    ausfindig gemacht. Dabei fiel auf, dass der Aspekt der Vorfreude auf den Film, das Versetzen in die
                    richtige Stimmung und das persönliche Erlebnis weder durch den Prozess noch die Umgebung unterstützt
                    werden. Aus diesen Beobachtungen konnte schlussgefolgert werden, dass der Experience des Kinobesuchs
                    gleichermaßen Beachtung geschenkt werden muss wie der Verbesserung des Leitsystems. Die Idee der
                    AR-Lösung ermöglicht es, dass gezielt Vorfreude auf den Film geschaffen wird, weil die
                    Besucher:innen in Stimmung versetzt werden und dadurch ein ganz persönliches Erlebnis im Kino
                    geschaffen wird.</p>
            </template>
        </text-module>


        <image-double imageNameOne="cinemar-process-assets-1.webp"
            alteTextOne="Ergebnis des Moodboards und des semantischen Differenzials für das HdM-Projekt CinemAR"
            imageNameTwo="cinemar-process-assets-2.webp"
            altTextTwo="Ausarbeitung der User Journey im CinemaxX und Sammlung von Verbesserungsmöglichkeiten des Erlebnisses im Projekt CinemAR"></image-double>

        <text-module side="left">
            <template #copy-text>
                <h3>Experience Design des Kinobesuchs</h3>
                <p>Nachdem die User Journey festgehalten wurde, konnten die weiteren Painpoints formuliert werden. Dies
                    ermöglicht das Erörtern der Optimierungsmaßnahmen des Leitsystems und die Orte für den Einsatz der
                    AR-Technologie. Aus dem Ideationphasen für das Erlebnisdesign wurden die besten Ideen weiter
                    verfolgt und entsprechend in Cluster übertragen und den Stationen der User Journey zugeordnet.</p>

                <p>So wurde das überarbeitete Erlebnis in einen ganzheitlichen Kontext gebracht, verschiedene
                    Abhängigkeiten der Leitsystemparameter sichtbar gemacht und ein lückenloser Ablauf im Konzept
                    sichergestellt.</p>

            </template>
        </text-module>

        <image-big imageName="cinemar-process-assets-3.webp"
            altText="Überblick über die Parameter des Experience Designs im Projekt CinemAR anhand einer Tabelle und Darstellung der User Journey im Kino-Grundriss" />
    </section>


    <section class="content-container">
        <text-module side="right">

            <template #copy-text>
                <h3>Design des klassischen Leitsystems</h3>
                <p>Während die Ausarbeitung der AR-Assets nur auf konzeptioneller Basis und exemplarisch für einen Film
                    erfolgte, wurde das Gestaltungssystem für das pragmatische Leitsystem vollständig ausgearbeitet.
                    Grundlage bildete abermals der Grundriss des Kinos, auf Basis dessen die genau Laufbahn und
                    Anordnung der Leitlinien, Standorte der Schilder und die unterschiedlichen Größenverhältnisse der
                    Leitsystembestandteile aufeinander abgestimmt werden konnten.</p>
            </template>
        </text-module>
        <image-double imageNameOne="cinemar-process-assets-4.webp"
            altTextOne="Konzept der neuen lichtbasierten Wegführung durch das Kino im Projekt CinemAR"
            imageNameTwo="cinemar-process-assets-5.webp"
            altTextTwo="Übersicht über die Gestaltungselemente des Leitsystems, Exploration des Schilddesigns und der Leitlinien im Projekt CinemAR"></image-double>
    </section>



    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "CinemarPage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        ImageDouble,
        FooterSection
    }

}
</script>

<style>

</style>