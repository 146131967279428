<template>
    <div class="btn" :class="type">
        <a :href="url">
            <slot name="text">Button</slot>
        </a>
    </div>
</template>

<script>

export default {
    name: "MyButton",
    props: {
        url: String,
        type: String
    }
}
</script>

<style>
.btn {
    display: inline-block;


    font-family: 'Work Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    text-decoration: none;

    padding: 15px 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.btn a {
    text-decoration: none;
}

.btn-primary {
    background-color: #BFB5AA;
    border: none;
}

.btn-primary a {
    color: #F5F5F5;
}

.btn-secondary {
    background-color: transparent;
    border: solid 1px #BFB5AA;
}

.btn-secondary a,
.btn-tertiary a {
    color: #BFB5AA;
}

.btn-tertiary {
    background-color: white;
    border: none;
}

.btn:hover {
    cursor: pointer;
}

.btn-primary:hover {
    background-color: #A39585;
    cursor: pointer;
}

.btn-secondary:hover {
    background-color: rgba(163, 149, 133, 0.2);
}

.btn-tertiary:hover {
    background-color: rgba(255, 255, 255, 0.6);
}

input[type="submit"]:hover {
    color: white;
}

@media only screen and (max-width: 960px) {
    .btn {
        font-size: 0.875em;
        padding: 10px 15px;
    }
}
</style>