import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Vue3Mq } from "vue3-mq";

createApp(App).use(router).use(Vue3Mq, {
    breakpoints: {
        sm: 0,
        lg: 960,
    }
}).mount("#app");