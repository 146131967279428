<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="schmetterlingseffekt-stage.webp">
        <template #ProjectHeadline>
            <h1>Der <span>Schmetterlings</span><span id="bindestrich">-</span><span>effekt</span></h1>
        </template>
        <template #Services>
            <p>User Research, Konzeption, Design</p>
        </template>
        <template #Description>
            <p>Bachelorarbeit, in der eine körperbezogene Aktivierungskommunikation für das Foodsharing-Café „Raupe
                Immersatt“ konzeptioniert und gestaltet wird.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Das Ausmaß der Lebensmittelverschwendung stellt ein globales, nationales und regionales Problem dar.
                    Von den ca. 1,3
                    Milliarden Tonnen Lebensmittelabfällen weltweit und ca. 12 Mio. Tonnen Lebensmittel jährlich in
                    Deutschland ist ein
                    großer Anteil vermeidbar. Ein Beispiel für Lösungsansätze gegen die Lebensmittelverschwendung
                    liefert das Konzept von
                    foodsharing wie es im Stuttgarter Café Raupe Immersatt praktiziert wird. Allerdings erfordert dieser
                    Ansatz eine rege
                    Beteiligung, an der es oftmals bei nachhaltigen Projekten noch mangelt. Um dieses Problem anzugehen,
                    wird eine
                    handlungsaktivierende Kommunikationsmaßnahme gestaltet, indem die Gesamtwirksamkeit des Cafés die
                    Wirksamkeit der
                    Einzelnen in den Fokus der Kommunikation gerückt wird.
                </p>

                <p>Die digitale Darstellung der Wirksamkeit für die am Prozess beteiligten Personen basiert auf einer
                    metaphorischen
                    Ausarbeitung und wirkt dadurch handlungsauffordernd und motiviert Besuchende des Cafés zu einer
                    Teilnahme am
                    Foodsharing-Angebot.
                </p>
            </template>
        </text-module>
        <video-big imageName="schmetterlingseffekt-mockup-animation.webm"></video-big>
    </section>

    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Die Herausforderung des Projektes ist einerseits die Verbindung des menschzentrierten
                        Gestaltungsprozesses mit dem
                        Embodied Design. Das Besondere ist hierbei, dass neben Aspekten wie das Erleben und die innen
                        liegenden Bedürfnisse der
                        Zielgruppe auch die Verkörperung in den Fokus der Analyse und Gestaltungslösung gerückt wird.
                    </p>
                    <p>
                        Die Brücke zwischen Kognition und Körper äußert sich zum Beispiel in metaphorischen Konzepten.
                        Nutzt man diese Brücke,
                        indem Metaphern bzw. metaphorische Konzepte oder die zugrunde liegenden Bildschemata in der
                        Gestaltung Verwendung
                        finden, kann die Wahrnehmung des Gestaltungsobjekts beeinflusst und mit den Eigenschaften von
                        Bildschemata in Beziehung
                        gesetzt werden kann.</p>
                </template>
            </text-module>
        </section>

        <image-double imageNameOne="schmetterlingseffekt-mockup-challenge-1.webp"
            imageNameTwo="schmetterlingseffekt-mockup-challenge-2.webp"></image-double>

        <section class="content-container">
            <text-module side="left">
                <template #copy-text>
                    <h3>Der Schmetterlingseffekt</h3>
                    <p>Die Metapher <span style="text-transform:uppercase;">Foodsharing ist ein Schmetterling</span>
                        vereint mehrere Bildschemata und metaphorische Konzepte, die sich als wirksam für die
                        Aktivierungskommunikation
                        herausgestellt haben.
                    </p>
                    <p>Schmetterlinge verkörpern Bewegung, sie vereinen die metaphorischen Konzepte der <span
                            style="text-transform:uppercase;">Bewegung</span> und <span
                            style="text-transform:uppercase;">Balance</span>.
                        Einerseits ist
                        ein Schmetterling erst wirksam, wenn er mit den Flügeln schlägt, andererseits wird der
                        Bewegungsrahmen gleich wie der
                        einer Wippe begrenzt. Dieser Aspekt kann genutzt werden: Je mehr Bewegung am Fairteiler des
                        Cafés registriert wird,
                        desto schneller bewegt sich der Schmetterling in der digitalen Darstellung (und umgekehrt).</p>
                    <p>
                        Schmetterlinge verkörpern Transformation, sie werden mit der
                        Metamorphose und der
                        Formveränderung in Verbindung gebracht. Veränderung sorgt für Wachstum und Reifung. Alles im
                        Leben muss
                        Entwicklungsstufen durchlaufen.
                    </p>
                    <p>Schmetterlinge verkörpern Farbvielfalt. Sie werden manchmal als fliegende Regenbögen beschrieben,
                        da sie meistens eine
                        große Palette
                        des Farbspektrums aufweisen. Farbigkeit beeinflusst die Attraktivität von Dingen, während blasse
                        Objekte ohne
                        Farbkontraste weniger attraktiv wirken und farblose Darstellungen mit negativen Eigenschaften
                        assoziiert werden. Auch
                        die Farbigkeit und der Anteil der farbigen Fläche des Schmetterlings werden aufgegriffen: Je
                        mehr Menschen sich am
                        Foodsharingprozess beteiligen, desto größer wird die bunte Fläche des Schmetterlings. Und je
                        unterschiedlicher die
                        entnommenen Lebensmittel sind, desto bunter ist das Muster, dass sich auf dem Schmetterling
                        abzeichnet.</p>
                </template>
            </text-module>
        </section>
    </div>

    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <h3>User centered design meets Embodiment</h3>
                <p>Das Problem einer zu geringen Teilnahme von Bürger:innen am Café-Konzept wird aus einer
                    menschzentrierten
                    Gestaltungsperspektive betrachtet. Jedoch vernachlässigen viele menschzentrierte Theorien und
                    Methoden bisher die
                    Komponente der Verkörperung (Embodiment), weshalb Erkenntnisse und Verfahrensweisen des Embodied
                    Designs hinzugezogen
                    werden, um die konventionelle menschzentrierte Gestaltung dadurch anzureichern. Die Grundlage der
                    gestalterischen
                    Vorgehensweise bietet die menschzentrierte Gestaltung, deren Gestaltungsprozess die enge
                    Zusammenarbeit mit den
                    beteiligten Personen des Problems voraussetzt. Im Zuge dessen ergeben sich bedeutsame und
                    erlebnisfördernde
                    Gestaltungslösungen, welche durch das ganzheitliche Verständnis der Zielgruppe und deren
                    Bedürfnisse zustande kommen.
                </p>
                <p>Um die zugrunde liegende metaphorischen Konzepte oder Bedürfnisse ermitteln und verstehen zu
                    können, benötigt man
                    eine tiefgehende Auseinandersetzung der impliziten und expliziten Kommunikation der Zielgruppe: Wie
                    läuft der Foodsharingprozess im Café ab?
                    Welche Bedürfnisse
                    lassen sich aus den Daten der Beteiligten ableiten? Welche metaphorischen Konzepte bilden die Basis
                    für das Denken, Handeln und Erleben der Befragten?</p>
            </template>
        </text-module>
        <text-module side="left">
            <template #copy-text>
                <p>Main Findings der Bedürfnisanalyse</p>
            </template>
        </text-module>
    </section>
    <image-double imageNameOne="schmetterlingseffekt-illustrationen-beduerfnispersonae.webp"
        imageNameTwo="schmetterlingseffekt-illustrationen-metaphernanalyse.webp"></image-double>
    <section class="content-container">
        <text-module side="right">
            <template #copy-text>
                <p>Main Findings der Metaphernanalyse</p>
            </template>
        </text-module>
        <text-module side="left">
            <template #copy-text>
                <h3>Gestaltung und Anwendungskontext</h3>
                <p>Aus den metaphorischen Konzepten von
                    Foodsharing und den
                    psychologischen Bedürfnissen der Zielgruppe
                    wird
                    eine metaphernbasierte Kommunikationsmaßnahme entwickelt, die in den Foodsharing-Prozess des Cafés
                    integriert werden
                    kann.</p>
                <p>Die Gestaltungsparameter wie Typografie, Farbwelt und Illustrationsstil orientieren sich am
                    bestehenden CI des Cafés und
                    werden ggfs. erweitert.</p>
            </template>
        </text-module>
        <image-big imageName="schmetterlingseffekt-styleguide-allgemein.svg" style="height: auto;"></image-big>
        <text-module>
            <template #copy-text>
                <p>Die Einbettung in den Anwendungskontext erfolgt über zwei Standorte. Einerseits wird ein Tablet als
                    Eingabegerät zur
                    Dokumentation
                    der Wirksamkeit durch die Foodsharer:innen zur Verfügung gestellt, das nah am Fairteiler platziert
                    ist. Andererseits
                    soll im Eingangsbereich des Cafés bereits eine breitere Masse an Besuchenden und damit potenziellen
                    Foodsharer:innen
                    erreicht werden.
                </p>
            </template>
        </text-module>

    </section>

    <image-double imageNameOne="schmetterlingseffekt-mockup-anwendungskontext-1.webp"
        imageNameTwo="schmetterlingseffekt-mockup-anwendungskontext-2.webp"></image-double>


    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import VideoBig from '@/components/VideoBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "SchmetterlingseffektPage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        VideoBig,
        FooterSection,
        ImageDouble

    }

}
</script>

<style>
@media only screen and (min-width: 480px) {
    #bindestrich {
        display: none;
    }
}
</style>