<template>
  <navigation-bar :logoStage="true"></navigation-bar>
  <image-stage background-image="thatsme.webp"> </image-stage>
  <section class="content-container">
    <headline-h-2>
      <template #headline-text>
        <h2>Kurz gesagt</h2>
      </template>
      <template #headline-underline>
        <h3>About me</h3>
      </template>
    </headline-h-2>
    <text-module>
      <template #copy-text>
        <p>Bereits während meiner Schulzeit begeisterte mich die Gestaltung von Informationen – damals noch in Form von
          PowerPoint Präsentationen. Seitdem entwickelte ich ein starkes Interesse für Design. Mit meiner Wahl
          Informationsdesign zu studieren, entschied ich mich 2018 dafür, meine berufliche Laufbahn in eine
          nutzerzentrierte Design- und Kommunikationsbranche zu lenken. Heute gestalte ich interaktive Oberflächen,
          Services und Erlebnisse und finde besonders faszinierend, dass bedeutsame und innovative Gestaltungslösungen
          erst dann entstehen können, wenn die Nutzenden in die Designprozesse miteinbezogen werden.</p>
        <p>Meine kreative Ader beschränkt sich jedoch nicht nur auf das digitale Milieu: Ich liebe Musik, spiele seit
          meiner Jugend gerne Klavier oder Gitarre (und das immer noch sehr gerne!), zeichne, male und arbeite gerne mit
          meinen Händen – alles ganz analog.</p>
        <p>
          Habe ich dein Interesse geweckt? Ich würde mich freuen, von dir zu
          hören.
        </p>
      </template>
      <template #button-one>
        <my-button type="btn-primary" url="/kontakt">
          <template #text>Let's chat</template>
        </my-button>
      </template>
    </text-module>
  </section>
  <div class="dark">
    <section class="content-container" id="content-aboutme">
      <headline-h-2>
        <template #headline-text>
          <h2 class="white">Erfahrung</h2>
        </template>
      </headline-h-2>
      <text-module side="right">
        <template #copy-text>
          <div class="education-entry">
            <p style="font-size: 14px">
              Sep. 2022 - heute | sternundhuber GmbH
            </p>
            <h3 style="margin-bottom: 30px">
              Junior UX Designer
            </h3>
            <p>Tätigkeit</p>
          </div>
          <div class="education-entry">
            <p style="font-size: 14px">
              Feb 2021 – Aug 2022 | sternundhuber GmbH
            </p>
            <h3 style="margin-bottom: 30px">
              User Experience & Interaktionskonzepte
            </h3>
            <p>Werkstudententätigkeit</p>
          </div>
          <div class="education-entry">
            <p style="font-size: 14px">
              Aug 2020 – Feb 2021 | sternundhuber GmbH
            </p>
            <h3 style="margin-bottom: 30px">Screendesign & Frontend</h3>
            <p>Praktikum</p>
          </div>
        </template>
      </text-module>
      <headline-h-2>
        <template #headline-text>
          <h2 class="white">Design- und Developmentsoftware</h2>
        </template>
      </headline-h-2>
      <text-module side="right">
        <template #copy-text>
          <div class="education-entry">
            <h3>Design & Prototyping</h3>

            <p>
              Figma | Sketch | Adobe Photoshop | Adobe Illustrator | Adobe
              Indesign (+ weitere Programme der Creative Cloud)
            </p>
          </div>

          <div class="education-entry">
            <h3>Webentwicklung</h3>
            <p>HTML, CSS | JavaScript | Vue.js</p>
          </div>
          <div class="education-entry">
            <h3>Content- und Projektmanagement</h3>
            <p>
              Projektmanagementtools (Asana, Trello) | CMS (TYPO3, FirstSpirit,
              Redaxo)
            </p>
          </div>
        </template>
      </text-module>
      <headline-h-2>
        <template #headline-text>
          <h2 class="white">Key Skills</h2>
        </template>
      </headline-h-2>
      <text-module side="right">
        <template #copy-text>
          <div class="education-entry">
            <h3>Nutzerzentriertes Design & Konzeption</h3>
            <p>
              User-Experience-Design | User-Interface-Design |
              Kommunikationsdesign | Information- Experience-Design | Embodied
              Design
            </p>
          </div>
          <div class="education-entry">
            <h3>Nutzerzentrierte Forschung</h3>
            <p>Qualitative Forschung | Quantitative Forschung | Datenanalyse</p>
          </div>
          <div class="education-entry">
            <h3>Kommunikation</h3>
            <p>Deutsch (Muttersprache) | Englisch (C1/C2) | Französisch (B1)</p>
          </div>
        </template>
      </text-module>
      <headline-h-2>
        <template #headline-text>
          <h2 class="white">Ausbildung</h2>
        </template>
      </headline-h-2>
      <text-module side="right">
        <template #copy-text>
          <div class="education-entry">
            <p style="font-size: 14px">
              Sept. 2018 - Aug. 2022 | Hochschule der Medien, Stuttgart
            </p>
            <h3 style="margin-bottom: 30px">
              Informationsdesign (Bachelor of Arts)
            </h3>
            <p style="font-size: 14px">Schwerpunkte | Abschlussnote 1,2</p>
            <p style="margin-bottom: 30px">
              Nutzerzentrierte Gestaltung & Forschung und Analyse
            </p>

            <p style="font-size: 14px">Bachelorthesis | Note 1,0</p>
            <p>
              Der Schmetterlingseffekt – Gestaltung einer körperbezogenen
              Aktivierungskommunikation für Foodsharing-Initiativen am Beispiel
              des Cafés "Raupe Immersatt"
            </p>
          </div>
          <my-button class="btn-secondary" url="/projekte/schmetterlingseffekt">
            <template #text> Zum Bachelor-Projekt </template>
          </my-button>
          <div class="education-entry">
            <p style="font-size: 14px">
              2009 – 2017 | Friedrich-Schiller-Gymnasium Fellbach
            </p>
            <h3 style="margin-bottom: 30px">ALLGEMEINE HOCHSCHULREIFE</h3>
            <p style="font-size: 14px">
              Leistungskurse | Abschlussnotendurchschnitt 1,5
            </p>
            <p>Englisch, Musik, Biologie</p>
          </div>
        </template>
      </text-module>
    </section>
  </div>
  <hr />
  <div class="dark">
    <section class="content-container">
      <footer-section />
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import ImageStage from "@/components/Stage.vue";
import HeadlineH2 from "@/components/HeadlineH2.vue";
import TextModule from "@/components/TextModule.vue";
import MyButton from "@/components/Button.vue";
import FooterSection from "@/components/FooterSection.vue";
export default {
  name: "AboutPage",
  components: {
    NavigationBar,
    ImageStage,
    HeadlineH2,
    TextModule,
    MyButton,
    FooterSection,
  },
};
</script>

<style scoped>
.dark h3,
.education-entry p:nth-child(1) {
  transform: translateX(-20%);
}

#content-aboutme {
  padding-top: 50px;
}

.white {
  color: #f5f5f5;
}

.education-entry {
  margin-bottom: 50px;
}

.btn-secondary {
  margin-bottom: 30px;
}

hr {
  border-color: #f5f5f5;
}

@media only screen and (max-width: 960px) {

  .dark h3,
  .education-entry p:nth-child(1) {
    transform: none;
  }

  .education-entry h3 {
    margin-top: 10px;
  }

  .education-entry p:nth-child(2) {
    margin-top: 0;
  }

  .dark .text-module-container {
    padding-bottom: 30px;
  }
}
</style>
