<template>
    <div class="headline-module" :class="type">
        <div class="headline-text">
            <div>
                <slot name="headline-text"></slot>
                <slot name="headline-underline"></slot>
            </div>
            <hr />
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeadlineH2',
    props: ['type']
}
</script>

<style>
.headline-module {
    width: 50%;
}

.headline-module.headline-right {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.headline-module.headline-right .headline-text,
.headline-right hr {
    margin: 0 0 0 auto;
}


h2 {
    font-family: Albra;
    font-size: 56px;
    font-weight: 400;
    line-height: 77px;
    letter-spacing: 0em;
    text-align: left;
    color: #171512;
    width: 585px;
    margin-bottom: 20px;
}

h3 {
    display: block;
    font-family: 'Work Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px
}

hr {
    width: 100px;
    border: solid 1px #BFB5AA;
    margin-left: 0
}

.headline-right .headline-text,
.headline-right .headline-text * {
    text-align: right;
    color: #F5F5F5;
}

.headline-right .headline-text h3 {
    color: #BFB5AA;
    height: 100%;
}

@media only screen and (max-width: 960px) {
    .headline-module {
        width: 100%;
        margin-bottom: 50px;
    }

    p {
        font-size: 0.875em;
    }

    h2 {
        font-size: 32px;
        line-height: 48px;
        width: 100%;
        margin-bottom: 15px;

    }

    h3 {
        font-size: 1.000em;
        line-height: 140%;
    }

}
</style>