<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="hdm-2880px.webp">
        <template #ProjectHeadline>
            <h1><span>Prüfungs</span><span v-if="smallScreen" id="bindestrich">-</span><span>anmeldung</span></h1>
        </template>
        <template #Services>
            <p>Research, Konzept, Design, Umsetzung</p>
        </template>
        <template #Description>
            <p>Konzeptionelle Überarbeitung und Entwicklung einer responsiven und nutzerfreundliche Version der
                HdM-Prüfungsanmeldung, infolge dessen Funktionen beibehalten und durch neue ergänzt und das Design
                modernisiert wurden.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Schlechtes Design kombiniert mit verwirrenden Funktionen – die Prüfungsanmeldung der Hochschule der
                    Medien Stuttgart (HdM) ist bei Weitem kein Paradebeispiel, wenn es um den hochschulinternen
                    Online-Auftritt geht. Seit längerer Zeit bedarf sowohl die visuelle Erscheinung als auch die
                    funktionale Konzeption und insbesondere die Anpassung an mobile Endgeräte eine umfassende
                    Generalüberholung. Die mangelnde Aufbereitung für mobile Endgeräte ist aus der Sicht der Nutzer mehr
                    zeitgemäß. Einige Aspekte der Prüfungsanmeldung sind weder nutzerfreundlich noch gestalterisch
                    durchdacht umgesetzt worden.</p>
                <p>Der Fokus liegt zunächst auf der konzeptionellen Weiterentwicklung der aktuellen Funktionen. Hierbei
                    sollen die alten Funktionen überarbeitet und neue Funktionen hinzugefügt werden. Die neuen
                    Funktionen entsprechen dem Nutzungskontext und ermöglichen den Nutzern ein positives Erlebnis durch
                    Erleichterung des Arbeitsaufwandes. In der Konzeption sticht neben der Überarbeitung der Funktionen
                    auch das Design hervor, da dieses ein großer Kritikpunkt des Projektteams ist. Das Design ist
                    hierbei modernisiert worden. Neben der konzeptionellen Weiterentwicklung liegt ein weiterer
                    Schwerpunkt auch auf der Frontend-Umsetzung, bei welcher u. a. Bootstrap zum Einsatz kommt.</p>

            </template>
        </text-module>
        <image-big imageName="pruefungsanmeldung-mockup-uebersicht.webp"></image-big>
    </section>

    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Prüfungen sind für die Studierenden ein sehr heikles Thema, da diese das Studium zu einem großen
                        Teil bestimmen. Kein Student möchte bei der Prüfungsanmeldung Fehler machen, weshalb ein
                        gewisser Stresspegel bei der Prüfungsanmeldung immer vorhanden ist. Durch die komplizierte
                        Aufmachung der Seite wird der Stress nur verstärkt. Deshalb ist es sinnvoll, die Studierenden zu
                        entlasten und die – nicht vorhandene – User Experience der Prüfungsanmeldung auszubauen, um den
                        Studenten ein positives Erlebnis bei der Prüfungsanmeldung zu ermöglichen. Durch diese
                        angedachten Elemente soll den Studierenden in der Prüfungsanmeldung Sicherheit verschafft und
                        die Vorgehensweise einfacherer und verständlicher werden. Dadurch verbessern sich sowohl die
                        Usability als auch die User Experience.</p>
                    <p>Zum Zeitpunkt des Projektes weist die Prüfungsanmeldung einige Mängel auf. Auf den ersten Blick
                        springt vor allem das veraltete Design ins Auge (welches außerdem nicht der CI der HdM
                        entspricht), dazu kommen ein wenig einheitliches und dadurch unruhig wirkendes Layout und eine
                        wenig erwartungskonforme Informationsarchitektur. Fatal ist jedoch die stark eingeschränkte
                        Usability der mobilen Version. Die Prüfungsanmeldung ist nicht responsiv gestaltet, weshalb sie
                        mobil fast gar nicht nutzbar ist. Dementsprechend ist es sinnvoll, die Funktionsweise und das
                        Design zu überarbeiten.</p>
                </template>
            </text-module>
        </section>

        <image-double imageNameOne="pruefungsanmeldung-mockup-anmeldung-alt.webp"
            imageNameTwo="pruefungsanmeldung-mockup-anmeldung-2.webp"></image-double>

        <section class="content-container">
            <text-module side="right">
                <template #copy-text>
                    <p>Sowohl das Funktionskonzept als auch das Design weisen grundlegende Veränderungen zum alten
                        Stand der Prüfungsanmeldung auf. Die Darstellungen wurden übersichtlich und responsiv angepasst,
                        die Farb- und Typografiewahl orientiert sich stark am CI der Hochschule. Außerdem werden die
                        User Flows zur An- und Abmeldung durch zeitliche Contextual Awareness zielgerichtet gesteuert,
                        sind in der Darstellung vereinfacht und übersichtlicher gestaltet. Das wird beispielsweise durch
                        die farbliche Codierung der unterschiedlichen Prüfungsarten, eine Filterfunktion und die
                        Suchleiste erreicht. Der Einsatz von Icons verringert die bisherige Textüberlastung im Viewport
                        ebenso wie die Akkordeon-Funktion, durch die weitere Daten zu einer Prüfung abgerufen werden
                        können.</p>
                </template>
            </text-module>
            <image-big imageName="pruefungsanmeldung-styleguide-allgemein.svg" style="height: auto;"></image-big>
        </section>
    </div>

    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Während des Überarbeitungsprozesses von Funktionen und Design wurden Methoden aus dem
                    menschzentrierten Gestaltungsprozess angewandt.</p>
                <h3>Wie wird die aktuelle Prüfungsan- und abmeldung bewertet?</h3>
                <p>Dies äußerte sich darin, dass zu Beginn eine nutzerzentrierte Analyse der aktuellen Probleme
                    durchgeführt wurde. Neben der Expertenevaluation durch das Projektteam selbst ergaben die
                    Umfrageergebnisse auch, dass für die An- und Abmeldung oft zum Laptop gegriffen wird. Für die Suche
                    nach relevanten Informationen zu einer Prüfung kommt allerdings das Handy vermehrt zum Einsatz. Auch
                    wurde der Eindruck vermittelt, dass die aktuelle Aufbereitung des Anmeldungsprozesses unpraktisch,
                    verwirrend und kompliziert erscheint.</p>
            </template>
        </text-module>
        <image-double imageNameOne="pruefungsanmeldung-mockups-prozess-1.webp"
            imageNameTwo="pruefungsanmeldung-mockups-prozess-2.webp"></image-double>
        <text-module side="left">
            <template #copy-text>
                <h3>Was benötigt man für die An- und Abmeldung?</h3>
                <p>Auf Basis der Ergebnisse konnte die Konzeption angestellt werden. Zunächst wurden die Funktionen
                    analysiert und gegebenenfalls neu konzipiert. In mehreren Iterationsschleifen wurden die Funktionen
                    hinterfragt und neue, nutzerfreundliche Alternativen gefunden.</p>
                <p>Weiterhin wurde die responsive Anordnung gestaltet. Grundsätzlich basiert das Layout auf dem
                    Grid-System von Bootstrap, nach dem Inhalte in ein 12-spaltiges Raster eingefügt werden. Die
                    Gestaltung der Oberfläche wurde für den Laptop umgesetzt und danach auf das Smartphone übertragen
                    und verfeinert. Bei der Prüfungsanmeldung treten hauptsächlich “Wechselnutzer” auf: Der Laptop wird
                    zur Anmeldung verwendet, das Smartphone unterwegs, um Anmeldung zu überprüfen oder den
                    Prüfungstermin zu kontrollieren. Außerdem kennzeichnet sich der Nutzungskontext dadurch, dass sich
                    Nutzer:innen bewusst Zeit für die Prüfungsanmeldung nehmen und Funktionen daher nicht extrem
                    vereinfacht werden müssen.</p>
                <h3>Welche Funktionen können wir verbessern?</h3>
                <p>Die drei Konzeptaspekte wurden in einem Figma-Klickprototypen vereint, welcher in der Veranstaltung
                    mit den Teilnehmenden evaluiert wurde. Das daraus resultierende Feedback wurde umgesetzt.</p>
            </template>
        </text-module>
    </section>
    <image-double imageNameOne="pruefungsanmeldung-mockup-ueberarbeitung-1.webp"
        imageNameTwo="pruefungsanmeldung-mockup-ueberarbeitung-2.webp"></image-double>
    <section class="content-container">
        <text-module side="left">
            <template #copy-text>
                <h3>Wie muss der Code aufgebaut sein?</h3>
                <p>Nach der Finalisierung der konzeptionellen Phase konnte die Umsetzung und Frontendentwicklung
                    beginnen.</p>
                <h3>Wie bewerten Nutzer unsere überarbeitete Version?</h3>
                <p>Das Endergebnis, – ein überwiegend funktionstüchtiger MVP, – wurde schlussendlich wieder mithilfe von
                    Usertests evaluiert. Die qualitative Auswertung zeigt eine deutliche Verbesserung der subjektiven
                    Wahrnehmung:
                <ul>
                    <li>Die Ansichten sind sehr übersichtlich und klar, nicht so gequetscht wie vorher.</li>
                    <li>Endlich ist die Prüfungsübersicht eine Übersicht mit den wichtigen Informationen
                        (Angabe mit Tag und Raum ist
                        gut)</li>
                    <li>Die Tool-Tipps könnten auf jeden Fall noch etwas spezifischer sein und auf die
                        Bedeutung der Abkürzung
                        hinweisen.</li>
                    <li>Durch die Context Awareness sehe ich auf der Startseite super schnell die
                        relevanten Infos</li>
                </ul>
                </p>
            </template>
        </text-module>

    </section>
    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    inject: ["mq"],
    name: "PruefungsanmeldungPage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        ImageDouble,
        FooterSection
    },
    computed: {
        smallScreen() {
            return this.mq.current == "sm";
        }
    }

}
</script>

<style>
@media only screen and (min-width: 410px) {
    #bindestrich {
        display: none;
    }
}
</style>