<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="moskitohelden-2880px.webp">
        <template #ProjectHeadline>
            <h1>Moskitohelden</h1>
        </template>
        <template #Services>
            <p>Kommunikationskonzept, Content, Design</p>
        </template>
        <template #Description>
            <p>Das Moskitohelden-Programm von <a class="dark" href="https://ireso.org" target="_blank">Ireso.org</a>
                leistet wichtige Aufklärungsarbeit im Hinblick auf die Krankheitsübertragung
                durch Tigermücken. Zur Unterstützung der Kampagne wurden die Lehrinhalte redesigned und ein
                Kommunikationskonzept und
                Design
                für die
                Öffentlichkeitsarbeit erarbeitet.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Das Konzept für die Öffentlichkeitsarbeit des Moskitoheldenprogramms umfasst sowohl die
                    gestalterischen Aspekte als auch Content der Website. Neben einer Designsprache wurden die textliche
                    Ansprache und alle Textbausteine der Webseite konzipiert.</p>
            </template>
        </text-module>
        <image-big imageName="moskitohelden-mockup-uebersicht.webp"
            altText="Laptop-, Tablet- und mobile Ansicht der Website für das Ireso-Programm 'Moskitohelden'"></image-big>
    </section>

    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Zusätzlich zum neuen Anstrich, den die Lehrinhalte verpasst bekommen, ist auch die Kommunikation
                        der Ireso-Kampagne neu definiert worden. Dabei werden sowohl Erwachsene als auch Kinder
                        gleichermaßen durch Design und Inhalt angesprochen.</p>
                    <p>Herzstück der Contenterstellung ist daher die metaphernbasierte Ansprache. Sie leitet die
                        Leser:innen durch die Seiten, weckt weitere Assoziationen und lässt die Sprache lebendig wirken.
                        Der Slogan <i>Weil’s uns juckt
                        </i> oder spritzige Einwürfe wie <i>
                            Warum wir die Mücke zum Elefanten machen müssen
                        </i> beleben das Thema der Krankheitsübertragung, ermöglichen eine spielerische
                        Auseinandersetzung
                        und wecken die Neugierde der Leser:innen. </p>
                </template>
            </text-module>
        </section>

        <image-double imageNameOne="moskitohelden-mockup-texte-1.webp"
            altTextOne="Laptop auf dem die Webseite 'Helden-Programm' des Moskitohelden Projekts geöffnet ist."
            imageNameTwo="moskitohelden-mockup-texte-2.webp"
            altTextTwo="Laptop auf dem das Kontaktformular des Moskitohelden Projekts geöffnet ist."></image-double>

        <section class="content-container">
            <text-module side="left">
                <template #copy-text>
                    <p>Die Infrastruktur der Lehrmaterialien ist ebenfalls semantisch eingebettet. Denn das
                        <i>Heldenprogramm</i> mitsamt den Materialien zur Aufklärung oder die <i>Heldentaten</i>, die in
                        den
                        <i>Heldengeschichten</i> erzählt werden, bedient sich dem Assoziationspool des Heldentums – und
                        das
                        steckt bereits im Namen der Kampagne.
                    </p>
                    <p>Außerdem werden Assoziationen zum erlebnis- und bewegungsreichen Bereich Sport getriggert. Die
                        Moskitohelden <i>treten gegen Krankheitsübertragungen an</i>, oder sie <i>punkten</i> mit Wissen
                        und erhalten
                        durch den Download des Heldenprogramms den <i>Startsprung</i> als Moskitoheld:in.</p>
                </template>
            </text-module>
        </section>
    </div>

    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Die Menschzentrierung wurde auch in diesem Projekt an erste Stelle gestellt. Dafür wurden in enger
                    Zusammenarbeit mit den Projektinitiatoren Personas und Nutzungsszenarien für die Webseite und
                    Lehrinhalte entworfen. So konnte die Ausarbeitung des metaphernorientierten Kommunikationskonzepts
                    durch die Vorarbeit verifiziert und finalisiert werden. Auf die Szenarien abgestimmte Wireframes für
                    die Abbildung der Interaktionsabläufe bilden die Basis der Contenterstellung der Texte und Bilder
                    und der Gestaltung.</p>
            </template>
        </text-module>
        <image-double imageNameOne="moskitohelden-mockups-prozess-1.webp"
            altTextOne="Darstellung der verschiedenen Schritte im Prozess des Kommunikationskonzepts für das Moskitoheldenprojekts, wie der Erstellung relevanter Personas, dem Wireframing und Screendesign des Moskitoheldenprojekts"
            imageNameTwo="moskitohelden-mockups-prozess-2.webp"
            altTextTwo="Darstellung der verschiedenen Schritte im Prozess des Kommunikationskonzepts für das Moskitoheldenprojekts, wie der Sammlung benötigter Texte, der Texterstellung und Dokumentation"></image-double>

    </section>
    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "MoskitoheldenPage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        ImageDouble,
        FooterSection
    }

}
</script>

<style>

</style>