<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="vonderzeit-2880px.webp">
        <template #ProjectHeadline>
            <h1>Von der Zeit</h1>
        </template>
        <template #Services>
            <p>Content, Layout, Editing</p>
        </template>
        <template #Description>
            <p>Das Magazin-Feature "Von der Zeit – oder warum die Grauen Herren es nie leichter hatten als heute.",
                beschreibt den Balanceakt zwischen Alltag und Freizeit, Terminen und Langeweile und wie Freiheit sich am
                besten anfühlen sollte.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>In der Ausgabe <i>Mach mehr</i> des fiktiven Studienmagazins Strichpunkt; fordern die einzelnen
                    Artikel auf unterschiedliche Weise dazu auf, den Blick auf das eigene Leben geradezurücken.</p>
                <p> Der Artikel <i>Von der Zeit</i> verdeutlicht, dass das Motto "Mach mehr" auch bedeuten kann, einen
                    Gang zurückzuschalten. Oder weniger Handy, weniger Zeitdiebe und weniger graue Herren am Alltag
                    teilnehmen zu lassen. Es wird erörtert, dass ein erfüllteres Leben qualitativ hochwertig verbrachte
                    Zeit und die ein oder andere Lücke im Terminkalender dazu führen können, dass sich die verbrachte
                    Lebenszeit nicht verschwendet anfühlt. Und dass Alltagsstress und Langeweile manchmal mehr
                    miteinander gemeinsam haben, als man denkt. Passend zum Inhalt des Features wurden weiterführende
                    Materialien eingebettet und eine allgemeine Bildsprache gefunden, die sich in das vordefinierte
                    Corporate Design des Magazins einfügt.</p>
            </template>
        </text-module>

    </section>
    <image-big imageName="vonderzeit-mockup-uebersicht.webp"></image-big>
    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Die Challenge des Projektes untergliedert sich in die individuelle Arbeit und die
                        redaktionsteamübergreifenden Aufgaben. Von der Idee bis zur Finalisierung eines einzelnen
                        Beitrages müssen Texte erstellt, passende Bilder eingefügt und weiterführendes Material
                        recherchiert werden, dass die Artikelinhalte thematisch anreichert. Außerdem ergänzen
                        Multimediainhalte für externe Kanäle wie Instagram die Artikel bzw. ein eine übergeordnete
                        Pressemitteilung das Magazin.</p>
                    <p>Neben der individuellen Artikelerstellung ist die Challenge des Projekts ein Spagat zwischen der
                        Vereinheitlichung des Magazinkonzepts bezüglich Design und Layout und der Gestaltung einer
                        abwechslungsreichen und interessanten Inhaltsanordnung.</p>
                </template>
            </text-module>

        </section>
        <image-double imageNameOne="vonderzeit-mockup-texte-1.webp"
            imageNameTwo="vonderzeit-mockup-texte-2.webp"></image-double>
    </div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Das Redaktionsteam, bestehend aus den Informationsdesignstudierenden, schuf zunächst den thematischen
                    Rahmen für das Magazin. In diesen sollten sich alle eingereichten Beiträge einordnen können. Weitere
                    Anforderungen an die inhaltliche Klammer war auch die Relevanz des Themas für aktuelle oder
                    zukünftige Studierende der Hochschule bzw. des Studiengangs und dass dieses am Puls der Zeit liegt.
                </p>
            </template>
        </text-module>

        <image-big imageName="vonderzeit-mockups-instagram.webp" data-aos="fade-up"></image-big>

        <text-module side="left">
            <template #copy-text>
                <p>Nach der individuellen Arbeit der Texterstellung und Assetrecherche zu den Beiträgen wurden die
                    Styleguiderichtlinien des Magazins angewandt, eine einheitliche Bildsprache gefunden und die
                    Artikelreihenfolge im Redaktionsteam diskutiert und festgelegt. In Arbeitsgruppen wurden die Inhalte
                    für Füllseiten, das Editorial oder Haikus erstellt, einzelne Fotoshootings für bestimmte
                    Magazinabschnitte organisiert und schlussendlich die druckfertige Version der Magazinausgabe
                    finalisiert.</p>
            </template>
        </text-module>

        <image-double imageNameOne="vonderzeit-mockup-prozess-1.webp"
            imageNameTwo="vonderzeit-mockup-prozess-2.webp"></image-double>
    </section>


    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "VonderZeitPage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        ImageDouble,
        FooterSection
    }

}
</script>

<style>

</style>