<template>
    <section class="project-stage-container" v-if="!smallScreen">
        <div class="project-stage-image" :style="[stageImage]">
            <div class="information"
                :style="isEqual ? 'position: fixed;' : 'position: relative; top: calc(0.45*' + windowHeight + 'px);'">
                <slot name="ProjectHeadline"></slot>
                <div class="information-colums">
                    <div class="project-stage-info-left">
                        <h3>Scope</h3>
                        <slot name="Services">Lorem</slot>
                    </div>
                    <div class="project-stage-info-right">
                        <h3>Beschreibung</h3>
                        <slot name="Description">Lorem ipsum</slot>
                    </div>
                </div>
            </div>
        </div>
        <span class="equalheight"></span>
    </section>
    <section class="project-stage-container" v-if="smallScreen">
        <div class="project-stage-image" :style="[stageImage]">
            <div class="information"
                :style="isEqualmobile ? 'position: fixed;' : 'position: relative; top: calc(0.6*' + windowHeight + 'px);'">
                <slot name="ProjectHeadline"></slot>
                <div class="information-colums">
                    <div class="project-stage-info-left">
                        <h3>Scope</h3>
                        <slot name="Services">Lorem</slot>
                    </div>
                    <div class="project-stage-info-right">
                        <h3>Beschreibung</h3>
                        <slot name="Description">Lorem ipsum</slot>
                    </div>
                </div>
            </div>
        </div>
        <span class="equalheight"></span>
    </section>
</template>

<script>

export default {
    inject: ["mq"],
    name: 'StageProjects',
    props: {
        backgroundImage: String,
    },
    data() {
        return {
            windowTop: 0,
            windowHeight: window.innerHeight
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop;
            console.log({ top: this.windowTop })
        }
    },
    computed: {
        bgImage() {
            // eslint-disable-next-line no-undef
            return require("@/assets/images/" + this.backgroundImage);
        },
        stageImage() {
            return {
                backgroundImage: `url(${this.bgImage})`
            };
        },
        isEqual: function () {
            return this.windowTop <= 0.45 * this.windowHeight
        },
        isEqualmobile: function () {
            return this.windowTop <= 0.6 * this.windowHeight
        },
        smallScreen: function () {
            return this.mq.current == "sm";
        }
    }
}
</script>

<style>
.project-stage-container {
    background-color: inherit;
    height: 150vh;
    width: 100vw;
    background-color: rgb(23 21 18);
    /* border-radius: 0 0 40px 40px; */

}

.project-stage-image {
    display: flex;
    align-items: flex-end;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}

.equalheight {
    display: block;
    width: 100vw;
    height: 30vh;
    margin: 0;
    z-index: 1;

}

h1 {
    font-family: 'Albra';
    font-style: normal;
    font-weight: 400;
    font-size: 130px;
    line-height: 2;
    color: #F5F5F5;
}

.project-stage-container h3 {
    color: #BFB5AA;
}

.information {
    padding: 0 clamp(0.5%, 150px, 10%);
    max-width: 1440px;
}

.information-colums {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding-bottom: 3rem;
    align-items: stretch;

}

.information-colums>div {
    width: 50%;
}

.information p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin: 0px;
    color: #F5F5F5;
}

@media only screen and (max-width: 960px) {
    .project-stage-container {
        height: 170vh;
    }

    .information h1 {
        font-size: 36px;
        line-height: 140%;
        width: 100%;
        word-wrap: break-word;

    }

    .information span {
        display: inline-block;
    }

    .information-colums {
        flex-direction: column;
    }

    .information-colums>div {
        width: 100%;
    }

    .information-colums>div:first-of-type {
        margin-bottom: 15px;
    }

    .information p {
        font-size: 14px;
        line-height: 140%;
    }

}

@media only screen and (min-width: 961px) and (max-width: 1300px) {
    h1 {
        font-size: 6em;
    }
}
</style>