<template>
    <navigation-bar :logoStage="false"></navigation-bar>
    <image-stage background-image="jenga-tower.webp">
        <template #logo>
            <div class="logo">
                <img src="../assets/images/logo.svg" />
                <p>{{ logoStage }}</p>
            </div>
        </template>
        <template #arrow>
            <div class="arrow bounce" @click="scrollMeTo('content')">
                <img src="../assets/icons/arrow-left.svg" />
            </div>
        </template>
    </image-stage>
    <div ref="content"></div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>People ignore design that ignores people.</h2>
            </template>
            <template #headline-underline>
                <h3>Frank Chimero</h3>
            </template>
        </headline-h-2>
        <TextModule side="right">
            <template #copy-text>
                <p>Das Einbeziehen des Menschen im Designprozess eröffnet für mich eine Welt, in der Gestaltungslösungen
                    intuitiv, leicht zugänglich und bedürfnisbefriedigend sind. Eine Welt, in der ich ermutigt werde,
                    aus der eigenen Perspektive herauszutreten und die Welt mit den Augen unserer Nutzer zu sehen, um
                    ihre Wünsche und Bedürfnisse zu erkennen. In der es beim Gestalten nicht einfach nur darum geht, ein
                    optisch ansprechendes oder gut funktionierendes Produkt zu entwickeln, – sondern darum, ein
                    Verständnis für die Nutzer:innen des Produktes zu entwickeln.</p>
                <p>Dieses Portfolio gewährt einen Einblick in diese Welt der menschzentrierten Gestaltungsprozesse,
                    mein Design-Denken und die Faszination für die Fähigkeit, komplexe Systeme in einfache, elegante
                    und lebendige Lösungen zu verwandeln.</p>
            </template>
            <template #button-one>
                <my-button type="btn-primary" url="/projekte">
                    <template #text>
                        Tiefer eintauchen
                    </template>
                </my-button>
            </template>

            <template #button-two>
                <my-button type="btn-secondary" url="/kontakt">
                    <template #text>
                        Let's chat
                    </template>
                </my-button>
            </template>
        </TextModule>
    </section>
    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Einblicken</h2>
                </template>
                <template #headline-underline>
                    <h3>Projekthighlights</h3>
                </template>
            </headline-h-2>
        </section>
        <image-carousel></image-carousel>
    </div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Designexpertise</h2>
            </template>
            <template #headline-underline>
                <h3>Über mich</h3>
            </template>
        </headline-h-2>
        <list-section></list-section>
    </section>
    <contact-form></contact-form>
    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import ImageStage from '@/components/Stage.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import MyButton from '@/components/Button.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import ListSection from '@/components/ListSection.vue';
import ContactForm from '@/components/ContactForm.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "HomePage",
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s | lara designs'
    },
    components: {
        NavigationBar,
        ImageStage,
        HeadlineH2,
        TextModule,
        MyButton,
        ImageCarousel,
        ListSection,
        ContactForm,
        FooterSection
    },
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop - 100;

            window.scrollTo({ top: top, behavior: 'smooth' });
        }
    }
}
</script>

<style>

</style>