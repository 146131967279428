<template>
    <div class="video-big-container">
        <video autoplay loop preload="auto" muted>
            <source class="video-one-big" :src="srcImage" type="video/webm" :alt="altText">
        </video>
    </div>
</template>

<script>
export default {
    name: 'VideoBig',
    props: {
        imageName: String,
        altText: String,
    },
    computed: {
        srcImage() {
            // eslint-disable-next-line no-undef
            return require("@/assets/images/" + this.imageName);
        }
    }
}
</script>

<style>
.video-big-container {
    height: 90vh;
    /* max-width: 1440px; */
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 150px;
    /* object-fit: cover; */
}

.video-one-big {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 90vh;
    object-fit: contain;
}

@media only screen and (max-width: 960px) {
    .video-big-container {
        height: 50vh;
        width: 100%;
        margin: 0;

    }
}

@media only screen and (min-width: 961px) {
    .video-big-container {
        width: 100%;
    }
}
</style>
