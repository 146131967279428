<template>
    <navigation-bar :logoStage="true" showBlack="true"></navigation-bar>
    <div style="height: 100px;"></div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Projekte</h2>
            </template>
            <template #headline-underline>
                <h3>Was ich mache</h3>
            </template>
        </headline-h-2>
        <image-gallery />
    </section>
    <div class="dark">
        <section class="content-container">
            <footer-section />
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import ImageGallery from '@/components/ImageGallery.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "ProjectsPage",
    components: {
        NavigationBar,
        HeadlineH2,
        ImageGallery,
        FooterSection
    }

}
</script>

<style>

</style>