<template>
    <div class="contact-container">
        <div class="contact-flexbox">
            <div class="contact-left">
                <h1 id="contact">Say hi!</h1>
                <form class="vue-form" @submit.prevent="submit" method="POST" accept-charset="UTF-8"
                    action="https://www.formbackend.com/f/7b5baa6c30706e7f">

                    <fieldset>
                        <div>
                            <label class="label" for="textarea">Message</label>
                            <textarea class="message" name="textarea" id="textarea"
                                placeholder="Deine Nachricht an mich… *" required="" v-model="message.text"
                                :maxlength="message.maxlength"></textarea>
                            <span class="counter">{{ message.text.length }} / {{ message.maxlength }} Zeichen</span>
                        </div>

                        <div>
                            <label class="label" for="name">Name</label>
                            <input type="text" name="name" id="name" required="" v-model="name" placeholder="Name *">
                        </div>
                        <div>
                            <label class="label" for="email">Email</label>
                            <input type="email" name="email" id="email" required=""
                                :class="{ email, error: !email.valid }" v-model="email.value"
                                placeholder="E-Mail Adresse *">
                        </div>
                        <div class="error-message">
                            <p v-show="!email.valid">Oh, bitte gib eine korrekte E-Mail-Adresse ein.</p>
                        </div>
                        <div class="data-checkbox">
                            <input type="checkbox" name="dataconsent" id="data-protection" required=""
                                :class="{ dataconsent, error: !consent.given }" v-model="consent.value">
                            <label for="dataconsent">Ich akzeptiere die <a class="light"
                                    href="?">Datenschutzbestimmungen</a>.</label>
                        </div>

                        <div>
                            <input type="submit" value="Nachricht senden" class="btn btn-tertiary">
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        <div class="contact-right" v-if="!smallScreen">
        </div>
    </div>

</template>

<script>
// Regular expression from W3C HTML5.2 input specification:
// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail
//eslint-disable-next-line
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export default {
    inject: ["mq"],
    name: 'ContactForm',
    // the instance state
    data: function () {
        return {
            name: "",
            email: {
                value: "",
                valid: true
            },
            message: {
                text: ``,
                maxlength: 500
            },
            consent: {
                valid: false
            },
            submitted: false
        };
    },
    methods: {
        // submit form handler
        submit: function () {
            this.submitted = true;
        },
        // validate by type and value
        validate: function (type, value) {
            if (type === "email") {
                this.email.valid = this.isEmail(value) ? true : false;
            }
        },
        validating: function (name, value) {
            if (name === "dataconsent") {
                this.dataconsent.valid = this.isConsent(value) ? true : false;
            }
        },
        // check for valid email adress
        isEmail: function (value) {
            return emailRegExp.test(value);
        },

        // // check or uncheck all
        // checkAll: function (event) {
        //     this.selection.features = event.target.checked ? this.features : [];
        // }
    },
    watch: {
        // watching nested property
        "email.value": function (value) {
            this.validate("email", value);
        }
    },
    computed: {
        largeScreen() {
            return this.mq.current === "lg";
        },
        smallScreen() {
            return this.mq.current == "sm";
        }
    }
};
</script>

<style>
.contact-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    background: #BFB5AA;
    align-items: stretch;
    max-height: 100vh;
    min-height: 80vh;
}

.contact-flexbox {
    width: 50%;
    padding: 0 0 0 clamp(11%, 250px, 10%);
    align-content: center;
}

.contact-left {
    margin: 10% 0 5% clamp(15%, 150px, 10%);
    padding-right: 3rem;
}

.contact-right {
    width: 50%;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
    background-image: url('../assets/images/thatsme.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.vue-form {
    width: 100%;
}


#contact {
    font-family: 'Albra';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 89%;
    color: #F5F5F5;
    margin: 0;
}

fieldset {
    border: none;
    padding: 0;
    -webkit-appearance: none;

}

label {
    display: none;
}

input[type="text"],
input[type="email"],
textarea {
    -webkit-appearance: none;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #F5F5F5;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: none;
    margin-top: 30px;
    padding: 1%;
}

::placeholder,
label {
    color: rgba(0, 0, 0, 0.6);
}

.counter,
.error-message p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #F5F5F5;
    float: right;

}

input[type="submit"] {
    margin-top: 30px;
    color: #BFB5AA;
}

textarea {
    width: 100%;
    min-height: 100px;
    max-height: 400px;
    margin-bottom: 10px;
}

input[type="text"],
input[type="email"] {
    width: 100%;
}

.data-checkbox {
    height: 100%;
    margin-top: 50px;
}

input[type="checkbox"] {
    position: relative;
    z-index: 0;
    -webkit-appearance: none;
    appearance: none;
    background-color: rgba(0, 0, 0, 0.2);
    font: inherit;
    width: 1.15em;
    height: 1.15em;
    border: none;
    display: grid;
    place-content: center;
    float: left;
    margin: 0 15px 0 0;
}

input[type="checkbox"]::before {
    content: "";
    width: 0.9em;
    height: 0.9em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #F5F5F5;
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

[type="checkbox"]+label {
    display: inline-block;
    margin-bottom: 15px;
    width: 80%;
}

input[type="text"],
input[type="email"],
textarea {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    outline: none;
}

@media only screen and (max-width: 960px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-flexbox {
        width: 100%;
        padding: 0;
    }
}
</style>