<template>
    <div class="content-container" v-if="smallScreen">
        <div class="image-double-container">
            <div data-aos="slide-right" data-aos-once="true" data-aos-ease="ease-out" data-aos-offset="-400">
                <img class="image-double" id="image-one" :src="srcImageDoubleOne" alt="altTextOne" />
            </div>
            <div data-aos="slide-left" data-aos-once="true" data-aos-ease="ease-out" data-aos-offset="-400">
                <img class="image-double" id="image-two" :src="srcImageDoubleTwo" alt="altTextTwo" />
            </div>
        </div>
    </div>

    <div class="image-double-container" v-if="!smallScreen">
        <img class="image-double" :src="srcImageDoubleOne" data-aos="slide-right" data-aos-once="true"
            data-aos-ease="ease-out" data-aos-offset="-400" alt="altTextOne" />
        <img class="image-double" :src="srcImageDoubleTwo" data-aos="slide-left" data-aos-once="true"
            data-aos-ease="ease-out" data-aos-offset="-400" alt="altTextTwo" />
    </div>

</template>

<script>
export default {
    inject: ["mq"],
    name: 'ImageDouble',
    props: {
        imageNameOne: String,
        imageNameTwo: String,
        altTextOne: String,
        altTextTwo: String
    },
    computed: {
        srcImageDoubleOne() {
            // eslint-disable-next-line no-undef
            return require("@/assets/images/" + this.imageNameOne);
        },
        srcImageDoubleTwo() {
            // eslint-disable-next-line no-undef
            return require("@/assets/images/" + this.imageNameTwo);
        },

        smallScreen: function () {
            return this.mq.current == "sm";
        }
    }
}
</script>

<style>
.image-double-container {
    position: relative;
    height: 80vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
    overflow: hidden;
    gap: 1vw;
}

.image-double {
    flex-shrink: 0;
    height: 100%;
    object-fit: scale-down;
    max-width: 100%;
    width: 70%;

}

@media screen and (max-width: 960px) {
    .image-double-container {
        position: relative;
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        overflow: hidden;
        gap: 1vh;
    }

    .image-double-container>div {
        height: 30vh;
    }

    .image-double {
        height: 150%;

    }

    #image-one {
        transform: scale(1.5);
        right: 90px;
        position: relative;
    }

    #image-two {
        transform: scale(1.5);
        position: relative;
        left: 50px;
        top: 20px;
    }
}
</style>