<template>
    <navigation-bar :logoStage="true" showBlack="true"></navigation-bar>
    <div style="height: 100px;"></div>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Impressum</h2>
            </template>
        </headline-h-2>
        <text-module>
            <template #copy-text>
                <h3>Angaben gem. § 5 des TMG:</h3>
                <p><b>Lara Wulf</b>
                    <br />Kreuzhaldenstr. 58, 70329 Stuttgart | Deutschland<br />+49 1573 8950199 | <a class="dark"
                        href="mailto:hi@larawulf.de">hi@lara.de</a>
                </p>

                <br />
                <h3>Verantwortlicher gem. § 55 Abs. 2 RStV</h3>
                <p>Lara Wulf
                    <br />
                    Kreuzhaldenstr. 58 <br /> 70329 Stuttgart
                </p>

                <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie
                    <a class="dark" href="https://ec.europa.eu/consumers/odr/">hier</a> finden.
                    Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle bin ich
                    nicht verpflichtet und nicht bereit.
                </p>
            </template>
        </text-module>
    </section>
    <div class="dark">
        <section class="content-container">
            <footer-section />
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    name: "ImprintPage",
    components: {
        NavigationBar,
        HeadlineH2,
        TextModule,
        FooterSection
    }

}
</script>

<style>

</style>