import { createWebHistory, createRouter } from "vue-router";

import HomePage from "@/pages/Home.vue";
import AboutPage from "@/pages/About.vue";
import ContactPage from "@/pages/Contact.vue";
import ProjectsPage from "@/pages/Projects.vue";
import ImprintPage from "@/pages/Imprint.vue";
import PrivacyPage from "@/pages/Privacy.vue";
import VonderzeitPage from "@/pages/projects/vonderzeit.vue";
import MoskitoheldenPage from "@/pages/projects/moskitohelden.vue";
import PruefungsanmeldungPage from "@/pages/projects/pruefungsanmeldung.vue";
import SchmetterlingseffektPage from "@/pages/projects/schmetterlingseffekt.vue";
import CinemarPage from "@/pages/projects/cinemar.vue";
import LearningExperiencePage from "@/pages/projects/learningexperience.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    desc: "Informationsdesign Portfolio von Lara Wulf – Tauche ein in die Welt der positiven UX, menschzentrierte Gestaltungs- und Kommunikationskonzepte.",
    ogImage: "logo.svg"
  },

  {
    path: "/about",
    name: "Über mich",
    component: AboutPage,
    desc: "Alles über den Werdegang von mir vom Studium über Arbeitserfahrungen und weiteren Skills.",
    ogImage: "logo.svg"
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: ContactPage,
    desc: "Kontaktiere mich bei Fragen oder Anregungen.",
    ogImage: "thatsme.webp"
  },
  {
    path: "/projekte",
    name: "Projekte",
    component: ProjectsPage,
    desc: "Ein Überblick über die Projekte aus den Bereichen des Informationsdesign, User Research und menschzentrierten Gestaltung."
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: ImprintPage,
    desc: ""
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: PrivacyPage,
    desc: ""
  },
  {
    path: "/projekte/vonderzeit",
    name: "Von der Zeit",
    component: VonderzeitPage,
    desc: "Magazin-Feature über den Balanceakt zwischen Alltag und Freizeit, Terminen und Langeweile und wie Freiheit sich am besten anfühlen sollte."
  },
  {
    path: "/projekte/moskitohelden",
    name: "Moskitohelden",
    component: MoskitoheldenPage,
    desc: 'Konzeption und Design eines Kommunikationskonzepts für die Kampagne "Moskitohelden" der Organisation Ireso.'
  },
  {
    path: "/projekte/pruefungsanmeldung",
    name: "Prüfungsanmeldung",
    component: PruefungsanmeldungPage,
    desc: "Konzeptionelle Überarbeitung und Entwicklung einer responsiven und nutzerfreundliche Version der HdM-Prüfungsanmeldung."
  },
  {
    path: "/projekte/schmetterlingseffekt",
    name: "Schmetterlingseffekt",
    component: SchmetterlingseffektPage,
    desc: "Bachelorarbeit – Gestaltung einer körperbezogenen Aktivierungskommunikation für das Foodsharing-Café „Raupe Immersatt“"

  },
  {
    path: "/projekte/cinemar",
    name: "CinemAR",
    component: CinemarPage,
    desc: ""

  },
  {
    path: "/projekte/learningexperience",
    name: "Learning Experience",
    component: LearningExperiencePage,
    desc: ""

  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | lara designs";
  document.querySelector('meta[name="description"]').setAttribute("content", to.desc);
  document.querySelector('meta[property="og:image"]').setAttribute("content", "../src/assets/images/" + to.ogImage)
  next();
});

export default router;