<template>
  <router-view />
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

export default {
  name: "app",
  mounted() {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: -100, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-top', // defines which position of the element regarding to window should trigger the animation

    });
  }
}
</script>

<style>
@import url('https: //fonts.googleapis.com/css?family=Work+Sans');

@font-face {
  font-family: 'Work Sans';
  src:
    url('./assets/fonts/WorkSans/WorkSans-VariableFont_wght.ttf') format('truetype'),
    url('./assets/fonts/WorkSans/WorkSans-Regular.woff') format('woff');

}

@font-face {
  font-family: 'Albra';
  src: url('././assets/fonts/Albra/Albra\ Regular.otf');
}

html,
body {
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'Work Sans';
  background-color: #F5F5F5;
  color: #171512;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-container {
  padding: 0 clamp(0.5%, 150px, 10%);
  max-width: 1440px;
  margin: 0 auto;
}

.dark {
  background-color: #171512;
  color: #F5F5F5;
}

.light {
  background-color: #F5F5F5;
}

a.light {
  color: rgba(0, 0, 0, 0.2);
  background-color: transparent;
}

a.dark {
  color: #BFB5AA;
  background-color: transparent;
}
</style>
