<script setup>
const items = [
    {
        title: "Der Schmetterlingseffekt",
        description: "2022 | Gestaltung einer Aktivierungskommunikation",
        link: "/projekte/schmetterlingseffekt",
        image: "schmetterlingseffekt-stage.webp",
        alt: "Innenraum und Fairteiler des Stuttgarter foodsharing Cafés 'Raupe Immersatt' am Hölderlinplatz",
    },
    {
        title: "CinemAR",
        description: "2022 | Gestaltung eines Leitsystems mit Erlebnischarakter",
        link: "/projekte/cinemar",
        image: "cinemar-gallery.webp",
        alt: "Leerer Kinosaal",
    },
    // {
    //     title: "Immersive Gardening",
    //     description:
    //         "Sem 6 | Konzeption einer Mixed Reality Anwendung für Heimgärtner:innen ",
    //     link: "/projekte/immersivegardening",
    //     image: "immersivegardening-gallery.webp",
    // },
    {
        title: "Learning Experience",
        description:
            "2020 | Usability Testing der eLearning Plattform von Festo Didactics",
        link: "/projekte/learningexperience",
        image: "learningexperience-gallery.webp",
        alt: "Ein Mann und eine Frau benutzen die 'Learning Experience' Platform von Festo Didactic auf einem Tablet",
    },
    {
        title: "Prüfungsanmeldung",
        description: "2020 | Überarbeitung des Prüfungsanmeldungsprozesses",
        link: "/projekte/pruefungsanmeldung",
        image: "pruefungsanmeldung-gallery.webp",
        alt: "Close Up des Hochschule der Medien Gebäudes mit dem Namen 'Schnitz' bei Sonnenuntergang",
    },
    // {
    //     title: "Happy Mail",
    //     description:
    //         "Sem 4 | Information Experience Design für positive Erlebnisse in der Coronapandemie",
    //     link: "/projekte/happymail",
    //     image: "planet-volumes-Ugy28X6RwAs-unsplash.jpg",
    // },
    // {
    //     title: "Drehmomente",
    //     description:
    //         "Sem 4 | Erstellung einer interaktiven Infografik in Processing",
    //     link: "/projekte/drehmomente",
    //     image: "leo-castro-s0EKwK7xzT8-unsplash.jpg",
    // },
    {
        title: "Moskitohelden",
        description:
            "2020 | Erarbeitung eines Kommunikationskonzepts für die Ireso-Kampagne „Moskitohelden“",
        link: "/projekte/moskitohelden",
        image: "moskitohelden-2880px.webp",
        alt: "Drei brasilianische Mädchen, die ein gelbes T-Shirt mit Slogan 'Heóis contra dengue' trage, zeigen auf das Aufklärungsmaterial der Ireso-Kampagne 'Moskitohelden'",
    },

    {
        title: "Von der Zeit",
        description:
            "2019 | Redaktion eines Features für das Magazin „Strichpunkt;“",
        link: "/projekte/vonderzeit",
        image: "vonderzeit-gallery.webp",
        alt: "Jenga-Tower aus Holz steht in der Natur",
    },
];
</script>

<template>
    <masonry-wall id="gallery-wall" :items="items" :gap="100" :ssr-columns="2" :column-width="500" v-if="!smallScreen">
        <template #default="{ item }">
            <div class="gallery-item" :style="{
                'margin-top': randomSpacing() + 'px',
                transform: 'translateX(' + randomSide() + '%)',
            }" data-aos="fade-up" data-aos-duration="2500" data-aos-offset="-500">
                <a class="normal" :href="item.link">
                    <div>
                        <img class="gallery-image" :src="require('@/assets/images/' + item.image)" :alt="item.alt" />
                        <h4>{{ item.title }}</h4>
                        <span>
                            <svg width="40" height="40" viewBox="0 0 60 40" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 20L39.5 20M39.5 20L33.5 13M39.5 20L33.5 27" stroke="#BFB5AA"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <circle cx="40" cy="20" r="19" transform="rotate(-90 40 20)" stroke="#BFB5AA"
                                    stroke-width="2" />
                            </svg>
                        </span>
                        <p>{{ item.description }}</p>
                    </div>
                </a>
            </div>
        </template>
    </masonry-wall>
    <masonry-wall id="gallery-wall" :items="items" :gap="100" :ssr-columns="1" :column-width="500" v-if="smallScreen">
        <template #default="{ item }">
            <div class="gallery-item" data-aos="fade-up" data-aos-duration="2500">
                <a class="normal" :href="item.link">
                    <div>
                        <img class="gallery-image" :src="require('@/assets/images/' + item.image)" :alt="item.alt" />
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.description }}</p>
                        <span>
                            <svg width="40" height="40" viewBox="0 0 60 40" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 20L39.5 20M39.5 20L33.5 13M39.5 20L33.5 27" stroke="#BFB5AA"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <circle cx="40" cy="20" r="19" transform="rotate(-90 40 20)" stroke="#BFB5AA"
                                    stroke-width="2" />
                            </svg>
                        </span>

                    </div>
                </a>
            </div>
        </template>
    </masonry-wall>
</template>

<script>
import { createApp } from "vue";
import MasonryWall from "@yeger/vue-masonry-wall";

const app = createApp();

app.use(MasonryWall);

export default {
    inject: ["mq"],
    name: "ImageGallery",
    data() {
        return {
            showWordIndex: null,
        };
    },
    methods: {
        randomSpacing: function () {
            return Math.floor(Math.random() * (300 - 1 + 1));
        },
        randomSide: function () {
            const number = Math.floor(Math.random() * (10 - -10 + 1) + -10);
            return number;
        },
    },
    computed: {
        smallScreen() {
            return this.mq.current == "sm";
        },
    },
};
</script>

<style>
#gallery-wall {
    align-self: center;
    align-items: center;
    margin: 100px 0;
}

.masonry-column:nth-child(odd)>div {
    align-self: flex-end;
}

/* 
.masonry-item {
    border: 1px solid blue;

} */

.gallery-item {
    width: 100%;
    overflow: visible;
}

.gallery-item:hover {
    cursor: pointer;
}

.gallery-image {
    width: 100%;
}

.masonry-item {
    width: 100%;
}

a.normal {
    text-decoration: none;
    color: #171512;
}

.gallery-item h4 {
    display: inline-block;
    font-family: "Albra";
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 0.5rem;
    width: 80%;
}

.gallery-item p {
    width: 80%;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #bfb5aa;
    margin-left: 0;
    margin-top: 0;
}

.gallery-item span {
    width: 100px;
    float: right;
}

.gallery-item:hover span {
    display: inline-block;
    position: relative;
    -moz-animation: bounce-right 1.5s infinite ease-in-out;
    -o-animation: bounce-right 1.5s infinite ease-in-out;
    -webkit-animation: bounce-right 1.5s infinite ease-in-out;
    animation: bounce-right 1.5s infinite ease-in-out;

}

.gallery-item svg {
    width: 128px;
    position: relative;
    display: inline;
    vertical-align: text-bottom;
    text-align: right;
    object-fit: cover;
    top: 40px;
}

@-webkit-keyframes bounce-right {
    0% {
        left: 0;
    }

    10% {
        left: -0.1em;
    }

    60% {
        left: 2em;
    }

    100% {
        left: 0;
    }
}

@-moz-keyframes bounce-right {
    0% {
        left: 0;
    }

    10% {
        left: -0.1em;
    }

    60% {
        left: 2em;
    }

    100% {
        left: 0;
    }
}

@-o-keyframes bounce-right {
    0% {
        left: 0;
    }

    10% {
        left: -0.1em;
    }

    60% {
        left: 2em;
    }

    100% {
        left: 0;
    }
}

@-ms-keyframes bounce-right {
    0% {
        left: 0;
    }

    10% {
        left: -0.1em;
    }

    60% {
        left: 2em;
    }

    100% {
        left: 0;
    }
}

@keyframes bounce-right {
    0% {
        left: 0;
    }

    10% {
        left: -0.1em;
    }

    60% {
        left: 2em;
    }

    100% {
        left: 0;
    }
}



@media only screen and (max-width: 960px) {
    .gallery-item svg {
        position: unset;
    }
}
</style>
