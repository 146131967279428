<template>
    <div class="text-module-container" :class="side">
        <div class="text-module-copytext">
            <div class="text-module-paragraph">
                <slot name="copy-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                    nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    vero
                    eos et
                    accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
                    Lorem
                    ipsum
                    dolor sit amet. Lorem ipsum dolor sit amet, consetetur.</slot>
            </div>
            <div>
                <slot name="button-one"></slot>
                <slot name="button-two"></slot>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'TextModule',
    props: ['side'],
}
</script>

<style>
.text-module-container {
    width: 100%;
    position: relative;
    padding-bottom: 100px;
}

.text-module-copytext {
    width: 50%;
    padding-left: 50%;
    right: 150px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

/* .text-module-copytext h3 {
    margin-top: 80px;
} */

.text-module-container.left .text-module-copytext {
    padding-left: 0px;
}

.text-module-paragraph {
    margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {
    .text-module-container {
        padding-bottom: 30px;
    }

    .text-module-copytext {
        width: 100%;
        padding: 0;
    }
}
</style>