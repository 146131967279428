<template>
    <div class="listskills">
        <p>User Experience & Interface Design</p>
        <hr />
        <p>User Research</p>
        <hr />
        <p>Embodied Design</p>
        <hr />
        <p>Konzeptionelle Informationsarchitektur</p>
        <hr />
        <p>Wireframing & Prototyping</p>
        <hr />
        <p>Kommunikation</p>
        <hr />
        <my-button type="btn-primary" url="/about">
            <template #text>
                <span>Mehr erfahren</span>
            </template>
        </my-button>
    </div>
</template>

<script>
import MyButton from './Button.vue'
export default {
    name: 'ListSection',
    components: {
        MyButton
    }

}
</script>

<style>
.listskills {
    width: 50%;
    margin-left: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    margin-bottom: 100px;
}

.listskills p {
    font-family: 'Albra';
    font-style: normal;
    font-weight: 300;
    font-size: 1.75em;
    line-height: 36px;
    margin: 0;
    padding: 0;
}

.listskills hr {
    width: 100%;
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 960px) {
    .listskills {
        width: 100%;
        margin-left: 0;
        gap: 14px;
    }

    .listskills p {
        font-size: 1.25em;
        line-height: 30px;
    }
}
</style>