<template>
    <navigation-bar :logoStage="true"></navigation-bar>
    <stage-projects background-image="learningexperience-2880px.webp">
        <template #ProjectHeadline>
            <h1><span>Learning Experience</span></h1>
        </template>
        <template #Services>
            <p>Testdesign, Moderation, Evaluation</p>
        </template>
        <template #Description>
            <p>Planung, Durchführung und Auswertung von remote durchgeführten Usability-Tests der Lernplattform „Festo
                Learning
                Experience“ von <a class="dark" href="https://festo.com/de/de/e/technische-bildung-id_3776/"
                    target="_blank">Festo
                    Didactic</a>.</p>
        </template>
    </stage-projects>
    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Das Projekt</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Eines der leistungsstärksten Instrumente, um ein bestehendes System auf die Nutzerfreundlichkeit,
                    Usability und positive Erlebnisse zu überprüfen, ist der Usability Test. Er ermöglicht es, mit den
                    Augen unserer Nutzer zu sehen und zu verstehen, wie sie mit dem Produkt interagieren. Zudem gewährt
                    er Einblicke in ihre Gedanken und Gefühle, während sie durch die Website oder Software navigieren.
                    Somit bietet ein Usabilitytest eine Gelegenheit, Painpoints, Hindernisse oder Bereiche der
                    Verwirrung zu identifizieren und Verbesserungen vorzunehmen, die das Produkt benutzerfreundlicher,
                    intuitiver und angenehmer machen. So soll auch der Usability Test für die Lernplattform „Festo
                    Learning Experience“ sicherstellen, dass das Lernerlebnis auf die Bedürfnisse unserer Nutzer
                    zugeschnitten ist und ihnen die bestmögliche Erfahrung bietet. Es handelt sich dabei um eine
                    Plattform, auf der Lehrende und Ausbilder Lerninhalte aufbereiten und bereitstellen und Lernende
                    diese Inhalte nutzen können, um sich fortzubilden. Der Fokus des digitalen Lernportals ist das
                    Erschaffen von individuellen Lernerlebnissen.
                </p>
                <p>Der Scope dieses Projektes umfasst alle Dimensionen eines User Tests: Die Analyse des
                    Nutzungskontextes der Lernplattform, die genaue Definition der Zielgruppe und die Konzeption des
                    Testdesigns. Ebenso fallen die Durchführung eines Pilottestes, Planung und Moderation der
                    Testtermine und die anschließenden Auswertung und Bewertung der eruierten Usability-Probleme in den
                    Aufgabenbereich des Projektes.</p>

            </template>
        </text-module>
        <image-big imageName="learningexperience-mockup-uebersicht.webp"
            altText="Erstellte Unterlagen wie den Screener, den Usability-Testbericht, das Testheft und den Moderationsleitfaden für den Usabilitytest der LX Plattform von Festo."></image-big>
    </section>

    <div class="dark">
        <section class="content-container">
            <headline-h-2 type="headline-right">
                <template #headline-text>
                    <h2>Die Challenge</h2>
                </template>
            </headline-h-2>
            <text-module side="left">
                <template #copy-text>
                    <p>Von der Rekrutierung über die Durchführung bis zur Auswertung – die Challenge eines
                        Usabilitytests ist es, eine ganzheitliche und detaillierte Vorgehensweise zu verfolgen und dabei
                        den mental Load für die Teilnehmenden so gering wie möglich zu halten. Eine präzise
                        Nutzungskontextanalyse, die Rekrutierung passender Probanden, die Durchführung eines Pilottestes
                        und realistische und testrelevante Aufgabenszenarien spielen dabei im Vorfeld der Tests eine
                        entscheidende Rolle, um repräsentative Ergebnisse zu erzielen. Nicht weniger wichtig sind
                        außerdem die souveräne Moderation, genaue Protokollierung der Abläufe und die strukturierte
                        Auswertung und Segmentierung der entlarvten Probleme.
                    </p>
                    <p>Als Moderatorin der Tests war es eine persönliche Herausforderung, die sprachlichen Anweisungen,
                        Hilfestellungen und Fragen souverän in den Flow einzubetten. Es ist besonders wichtig, auf die
                        Wortwahl und die Art und Weise der Hilfestellung sowie tiefgehende Fragen zu achten, da diese
                        eine entscheidende Rolle für die Verwendbarkeit der Aussagen spielt. Aus diesem Grund bewerte
                        ich das Projekt persönlich als besonders richtungsweisend für meine Moderationsfähigkeit in User
                        Tests.</p>
                    <p>
                    </p>
                </template>
            </text-module>
        </section>

        <image-double imageNameOne="learningexperience-mockup-challenge-1.webp"
            altTextOne="Laptop auf dem ein Zoom-Meeting läuft, während der Bildschirm mit der Lernplattform LX von Festo geteilt wird."
            imageNameTwo="learningexperience-mockup-challenge-2.webp"
            altTextTwo="Papiermockup des Moderationsleitfadens für den Usabilitytest der LX Plattform von Festo Didactic."></image-double>

    </div>

    <section class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Der Prozess</h2>
            </template>
        </headline-h-2>
        <text-module side="right">
            <template #copy-text>
                <p>Der graduelle Prozess, der bis zur Vollendung der Testings und Auswertung durchlaufen wurde, wird in
                    den folgenden Schritten umrissen.
                </p>
                <h3>Nutzungskontextanalyse</h3>
                <p>Wie ist die Nutzergruppe beschaffen? Um diese Frage zu beantworten, müssen die Nutzergruppen
                    beschrieben werden. Das Projektteam beschäftigt sich beispielsweise mit der Nutzergruppe der
                    Betreuer:innen bzw. Lehrenden, die in die ältere Nutzergruppe fallen und wenig technikaffin sind.
                    Diese Zielgruppe besitzt bestimmte Eigenschaften. Beispielsweise fehlt die Vorerfahrung mit diesem
                    oder einem vergleichbaren Produkt, ebenso wie ausschließlich Grundkenntnisse im Umgang mit Computern
                    vorhanden sind. Die definierten Punkte sind essenziell für die Rekrutierung, da notwendige und
                    hinreichende Eigenschaften durch die Proband:innen erfüllt sein müssen, um aussagekräftige
                    Ergebnisse erzielen zu können.</p>
                <p>Weitere Fragen, die im Zuge der Nutzungskontextanalyse aufkommen und beantwortet werden müssen, sind:
                    - Welche Aufgaben entstehen im Umgang mit der Lernplattform? Wie relevant sind diese für die
                    Evaluation? – Wodurch definiert sich die Umgebung, in der die Lernplattform genutzt wird? Wie
                    unterscheidet sich die Relevanz der Umgebungsparameter? - Welche Evaluationsziele sollen durch das
                    Usabilitytesting erzielt werden?</p>
            </template>
        </text-module>
    </section>

    <image-double imageNameOne="learningexperience-mockup-assets-1.webp"
        altTextOne="Merkmale der Zielgruppe des Usabilitytests, zusammengefasst in einem Screener"
        imageNameTwo="learningexperience-mockup-assets-2.webp"
        altTextTwo="Zielgruppenanalyse und Analyse des Nutzungskontextes für die Lernplattform LX von Festo Didactics inklusive der Aufgabenanalyse und Evaluationsziele"></image-double>
    <section class="content-container">
        <text-module side="left">
            <template #copy-text>
                <h3>Testkonstruktion</h3>
                <p>Im Zuge der Konzipierung des Testdesigns kommen neue Fragen auf.</p>
                <p>Wie werden die Teilnehmenden rekrutiert? Inwiefern stimmt der Pool aus Teilnehmenden mit der
                    analysierten Nutzergruppe aus Schritt 1 überein? Aus welchen Aufgaben setzt sich das Testdesign
                    zusammen und inwiefern tragen diese zum Erreichen der Evaluationsziele bei? Welche weiteren Aspekte
                    sollen abseits der Aufgabenbearbeitung evaluiert werden?</p>
                <p>Die gewählten Aufgaben können durch einen Abgleich mit den Evaluationszielen verifiziert werden und
                    anschließend in einen festgelegten Ablauf zum Beispiel in Form eines Moderationsleitfadens gebracht
                    werden.</p>
                <p>Dazu kommt die Frage nach der Testumgebung. Wie ist diese gestaltet? Welche Datenerhebungsverfahren
                    werden genutzt? In diesem Projekt wurde das Testsetting in einem remoten Kontext aufgebaut und dafür
                    Videoübertragungstools eingesetzt. Die Verwendung von der Methode des lauten Denkens oder einem
                    kurzen abschließenden Interview sind aus den meisten Usertestings nicht mehr wegzudenken – auch hier
                    fanden sie Verwendung. Gleichermaßen bietet es sich neben den qualitativen Methoden in vielen Fällen
                    an, standardisierte Fragebögen wie den INTUI (zur Evaluation der Intuitivität eines getesteten
                    Produktes) einzusetzen.</p>
            </template>
        </text-module>
    </section>
    <image-big imageName="learningexperience-mockup-assets-3.webp"
        altText="Ausschnitte aus der Auswertung des Usabilitytests: Übersicht der Usabilityprobleme mit Schweregrad-Priorisierung, detaillierte Ansicht eines Problems und Ausschnitt aus der Segmentierung der Testprotokolle"></image-big>
    <section class="content-container">
        <text-module side="right">
            <template #copy-text>
                <h3>Auswertung</h3>
                <p>Die Ausarbeitung der Protokolle und Mitschnitte der Tests wurde mit einer qualitativen Datenanalyse
                    begonnen. Einzelne Segmente werden danach beurteilt, ob</p>
                <ul>
                    <li>Teilnehmer nicht den optimalen Lösungsweg einer Testaufgabe durchläuft und in Folge dessen
                        fehlerhaft handelt,</li>
                    <li>sich kritische Ereignisse verketten oder</li>
                    <li>ein angestrebtes Aufgabenziel zwar effektiv erreicht, dies jedoch negativ kommentiert wird.</li>
                </ul>
                <p>Diese Segmente werden in Indizes zusammengefasst, welche benannt und beschrieben werden.
                    Beispielsweise definiert sich der Index <span style="text-transform:uppercase;">Platzierung
                        relevanter Funktionen</span> als <em>Alle Probleme, bei denen Funktionen entgegen von
                        Nutzererwartungen positioniert sind und dementsprechend nicht gefunden und/ oder die Platzierung
                        negativ kommentiert wurde.</em>
                </p>
                <p>Nach dem Prüfen auf jeweils korrekte Zuordnung der Segmente verdichtete sich die weitere
                    Vorgehensweise darin, dass auf Basis von Interpretationen aus den Indizes letztendlich
                    Usability-Probleme abgeleitet wurden. Diese wiederum werden nach ihrem Schweregrad priorisiert.
                    Wichtig für die Priorisierung ist: Wird es unproblematisch oder kompliziert für die Nutzer sein, das
                    Problem zu überwinden? Wie häufig trat das Usability-Problem in den Tests auf? Wie beständig wird
                    das jeweilige Usability-Problem womöglich vorhanden sein?
                    Dabei ordnen sich die Probleme von
                    0 <em>Ich stimme nicht zu. Dies ist kein Usability Problem</em> über 1 <em>kosmetisches Problem
                        (muss nicht geändert werden, es sei denn, es ist genügend Zeit verfügbar)</em>
                    bis hin zu 4 <em>Usability Desaster (muss unbedingt behoben werden)</em></p>
                <p>Um Möglichkeiten anzuführen, wodurch das Usability-Problem behoben werden kann, wurden u. a. auf
                    Basis von den gesammelten Erkenntnissen der Auswertung über die Lernplattform Empfehlungen
                    formuliert.</p>
            </template>
        </text-module>

    </section>
    <div class="dark">
        <section class="content-container">
            <footer-section></footer-section>
        </section>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import StageProjects from '@/components/StageProjects.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import TextModule from '@/components/TextModule.vue';
import ImageBig from '@/components/ImageBig.vue';
import ImageDouble from '@/components/ImageDouble.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    inject: ["mq"],
    name: "LearningExperiencePage",
    components: {
        NavigationBar,
        StageProjects,
        HeadlineH2,
        TextModule,
        ImageBig,
        ImageDouble,
        FooterSection
    },
    computed: {
        smallScreen() {
            return this.mq.current == "sm";
        }
    }

}
</script>

<style>
@media only screen and (min-width: 410px) {
    #bindestrich {
        display: none;
    }
}
</style>