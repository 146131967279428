<template>
    <navigation-bar :logoStage="true" showBlack="true"></navigation-bar>
    <div style="height: 100px;" v-if="!smallScreen"></div>
    <div style="height: 60px;" v-if="smallScreen"></div>
    <contact-form />
    <div class="content-container">
        <headline-h-2>
            <template #headline-text>
                <h2>Find me on social media</h2>
            </template>
            <template #headline-underline>
                <h3>Social Links</h3>
            </template>
        </headline-h-2>
        <list-section-icons></list-section-icons>
        <my-button style="display: none;"></my-button>
        <list-section style="display: none;"></list-section>
    </div>
    <div class="dark">
        <div class="content-container">
            <footer-section></footer-section>
        </div>
    </div>

</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
import ContactForm from '@/components/ContactForm.vue';
import HeadlineH2 from '@/components/HeadlineH2.vue';
import ListSectionIcons from '@/components/ListSectionIcons.vue';
import MyButton from '@/components/Button.vue';
import ListSection from '@/components/ListSection.vue';
import FooterSection from '@/components/FooterSection.vue';
export default {
    inject: ["mq"],
    name: "ContactPage",
    components: {
        NavigationBar,
        ContactForm,
        HeadlineH2,
        ListSectionIcons,
        MyButton,
        ListSection,
        FooterSection
    },
    computed: {

        smallScreen: function () {
            return this.mq.current == "sm";
        }
    }

}
</script>

<style>
a p {
    color: #171512;
}
</style>