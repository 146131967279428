<template>
  <section class="stage-container" :style="stageImage">
    <div class="stage-container-flex">
      <slot name="logo" :class="style"></slot>
    </div>
    <div class="arrow-container-flex">
      <slot name="arrow" href="#" :class="style"></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImageStage',
  props: {
    backgroundImage: String
  },
  computed: {
    bgImage() {
      // eslint-disable-next-line no-undef
      return require("@/assets/images/" + this.backgroundImage);
    },
    stageImage() {
      return {
        backgroundImage: `url(${this.bgImage})`
      };
    }
  }
};
</script>

<style>
.stage-container {
  height: 100vh;
  background-repeat: no-repeat;
  /* background-size: 100% auto; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  background-position: center;
}

.stage-container-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
}

.arrow-container-flex {
  display: flex;
  justify-content: center;
  position: relative;
  top: -7%;
}

@media only screen and (max-width: 960px) {
  .arrow-container-flex {
    top: -20%;
  }
}

.logo {
  height: 20vh;
  width: 100%;
}

.logo img {
  height: 100%;
  object-fit: contain;
}

.arrow:hover {
  cursor: pointer;
}

.arrow img {
  transform: rotate(270deg);

}

.bounce {
  display: inline-block;
  position: relative;
  -moz-animation: bounce 1.5s infinite linear;
  -o-animation: bounce 1.5s infinite linear;
  -webkit-animation: bounce 1.5s infinite linear;
  animation: bounce 1.5s infinite linear;
}

@-webkit-keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.5em;
  }

  70% {
    top: -0.1em;
  }

  100% {
    top: 0;
  }
}

@-moz-keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.5em;
  }

  70% {
    top: -0.1em;
  }

  100% {
    top: 0;
  }
}

@-o-keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.5em;
  }

  70% {
    top: -0.1em;
  }

  100% {
    top: 0;
  }
}

@-ms-keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.5em;
  }

  70% {
    top: -0.1em;
  }

  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.5em;
  }

  70% {
    top: -0.1em;
  }

  100% {
    top: 0;
  }
}

/* a {
  margin-top: auto;
  margin-bottom: 50px;
  transform: translate(0%, -50%);
  color: red;
} */
</style>