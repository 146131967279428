<template>
    <!-- data-aos="fade-up" data-aos-duration="500" -->
    <nav class="navigation-container black" v-if="smallScreen">
        <div class=" navigation-bar content-container">
            <div class="navigation-item">
                <a href="/">
                    <figure id="logo">
                        <img src="../assets/icons/logo-1row.svg" />
                    </figure>
                </a>
            </div>
            <div class="navigation-item" id="burger" @click="showNav = !showNav">

                <figure>
                    <transition name="fade" mode="out-in">
                        <img src="../assets/icons/burgermenu.svg" v-if="!showNav" />
                        <img src="../assets/icons/burgermenu-close.svg" v-else />
                    </transition>
                </figure>

            </div>
        </div>
    </nav>
    <transition name="navislide">
        <div id="mobile-menu" class="black" v-if="smallScreen && showNav">
            <div class="navigation-item">
                <a href="/projekte">
                    <p>Projekte</p>
                </a>
            </div>
            <div class="navigation-item">
                <a href="/about">
                    <p>Über mich</p>
                </a>
            </div>
            <div class="navigation-item">
                <a href="/kontakt">
                    <p>Kontakt</p>
                </a>
            </div>
            <div class="navigation-item">
                <figure>
                    <a href="https://www.linkedin.com/in/lara-wulf-396a2b213" target="_blank"> <img class="symbol"
                            src="../assets/icons/linkedin-logo-thin-white.svg" /></a>
                    <a href="https://www.instagram.com/laaaaaariiiii/" target="_blank"><img class="symbol"
                            src="../assets/icons/instagram-logo-thin-white.svg"
                            @mouseover="InstaLogo = InstaLogoHover" /></a>
                </figure>
            </div>

        </div>
    </transition>
    <div class="navigation-container" :class="isEqual || showBlack ? 'black' : ''" v-if="largeScreen">
        <div class=" navigation-bar content-container">
            <div class="navigation-item">
                <a href="/projekte">
                    <p>Projekte</p>
                </a>
            </div>
            <div class="navigation-item">
                <a href="/about">
                    <p>Über mich</p>
                </a>
            </div>
            <div class="navigation-item">
                <a href="/">
                    <figure id="logo">
                        <img src="../assets/icons/logo-1row.svg" v-show="isEqual || logoStage" />
                    </figure>
                </a>
            </div>
            <div class="navigation-item">
                <a href="/kontakt">
                    <p>Kontakt</p>
                </a>
            </div>
            <div class="navigation-item">
                <figure>
                    <a href="https://www.linkedin.com/in/lara-wulf-396a2b213" target="_blank"> <img class="symbol"
                            src="../assets/icons/linkedin-logo-thin-white.svg" /></a>
                    <a href="https://www.instagram.com/laaaaaariiiii/" target="_blank"><img class="symbol"
                            src="../assets/icons/instagram-logo-thin-white.svg"
                            @mouseover="InstaLogo = InstaLogoHover" /></a>
                </figure>
            </div>

        </div>
    </div>
</template>

<script>
export default {

    inject: ["mq"],
    name: 'NavigationBar',
    data() {
        return {
            windowTop: 0,
            windowHeight: window.innerHeight,
            showNav: false,
        };
    },
    props: {
        logoStage: Boolean,
        showBlack: Boolean
    },
    methods: {
        onScroll(e) {
            this.windowTop = e.target.documentElement.scrollTop + 100;
            this.showNav = false;
        },
        // toggleNav() {
        //     this.showNav = !this.showNav
        // }
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
        console.log(this.mq)
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.onScroll);
    },
    computed: {
        isEqual: function () {
            return this.windowTop >= this.windowHeight
        },
        largeScreen() {
            return this.mq.current === "lg";
        },
        smallScreen() {
            return this.mq.current == "sm";
        }
    }
};
</script>

<style scoped>
.symbol {
    margin-right: 15px;
    height: 27px;
}

.black {
    background-color: #171512;
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.navigation-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100px;
    z-index: 50;
}

.navigation-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

@media only screen and (max-width: 960px) {
    .navigation-container {
        height: 60px;
        position: fixed;
    }

    .navigation-bar {
        position: relative;
        height: 100%;
    }

    #logo {
        height: 30px;
        margin: 0;

    }

    #logo img {
        margin: 0;
    }

    .navigation-item {
        flex-grow: 0;
    }

    #burger figure {
        margin: 0;
        height: 30px;
    }

    #burger figure img {
        height: 100%;
    }

    #mobile-menu {
        position: fixed;
        z-index: 30;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 30px;
        margin-top: 60px;
    }

    #mobile-menu .navigation-item {
        display: block;
    }

    #mobile-menu .navigation-item:last-child figure {
        display: flex;
        margin: 0;
        justify-content: center;
    }

    #mobile-menu .navigation-item:last-child figure a {
        width: fit-content;
    }
}

@media only screen and (min-width: 961px) {
    .navigation-item {
        flex-grow: 1;
    }
}

.navigation-item {
    margin: 20px 0 20px;
    z-index: 100;
    flex-basis: 0;
}

.navigation-item figure {
    width: 100%;
}

.navigation-item:hover {
    cursor: pointer;
}

.navigation-item:hover p {
    color: #BFB5AA;
}

#logo {
    display: flex;
    margin: 0;
    justify-content: center;
}

#logo img {
    margin: 0 auto;
}

p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #F5F5F5;
}

a {
    text-decoration: none;
    height: 100%;
    width: 100%;
}

a:hover .symbol {
    filter: invert(0.5);
}

.navislide-enter-active {
    transition: all 0.5s ease-in-out;
    /* transform: translateY(100px); */
}

.navislide-leave-active {
    transition: all 0.5s ease-in-out;
}

.navislide-enter-from,
.navislide-leave-to {
    transform: translateY(-200px);
    opacity: 0;
}


.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>