<template>
    <Carousel :items-to-show="1.45" :wrap-around="true" :transition="500" v-if="!smallScreen">
        <Slide v-for="slide in images" :key="slide.id">
            <div class="carousel__item" @click="klick(slide)">
                <div class="carousel__itemcontainer">
                    <div class="carousel__numbering">sem n°{{ slide.sem }}</div>
                    <div class="carousel__image">
                        <img :src="require(`@/assets/images/${slide.url}`)" :alt="slide.alt" />
                    </div>
                    <div class="carousel__heading">
                        {{ slide.project }}
                        <div class="carousel__text">{{ slide.text }}</div>
                    </div>
                </div>
            </div>
        </Slide>

        <template #addons="{ slidesCount }">
            <Navigation v-if="slidesCount > 1">
                <template #next>
                    <span><svg width="60" height="40" viewBox="0 0 60 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 20L39.5 20M39.5 20L33.5 13M39.5 20L33.5 27" stroke="#BFB5AA" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="40" cy="20" r="19" transform="rotate(-90 40 20)" stroke="#BFB5AA"
                                stroke-width="2" />
                        </svg></span>
                </template>
                <template #prev>
                    <span><svg width="60" height="40" viewBox="0 0 60 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M59 20L20.5 20M20.5 20L26.5 27M20.5 20L26.5 13" stroke="#BFB5AA" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="20" cy="20" r="19" transform="rotate(90 20 20)" stroke="#BFB5AA"
                                stroke-width="2" />
                        </svg>
                    </span>
                </template>
            </Navigation>
        </template>
    </Carousel>
    <Carousel :items-to-show="1" :wrap-around="true" :transition="500" v-if="smallScreen">
        <Slide v-for="slide in images" :key="slide.id">
            <div class="carousel__item" @click="klick(slide)">
                <div class="carousel__itemcontainer">
                    <div class="carousel__numbering">sem n°{{ slide.sem }}</div>
                    <div class="carousel__image">
                        <img :src="require(`@/assets/images/${slide.url}`)" :alt="slide.alt" />
                    </div>
                    <div class="carousel__heading">
                        {{ slide.project }}
                        <div class="carousel__text">{{ slide.text }}</div>
                    </div>
                </div>
            </div>
        </Slide>
        <template #addons="{ slidesCount }">
            <Navigation v-if="slidesCount > 1">
                <template #next>
                    <span><svg width="60" height="40" viewBox="0 0 60 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 20L39.5 20M39.5 20L33.5 13M39.5 20L33.5 27" stroke="#BFB5AA" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="40" cy="20" r="19" transform="rotate(-90 40 20)" stroke="#BFB5AA"
                                stroke-width="2" />
                        </svg></span>
                </template>
                <template #prev>
                    <span><svg width="60" height="40" viewBox="0 0 60 40" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M59 20L20.5 20M20.5 20L26.5 27M20.5 20L26.5 13" stroke="#BFB5AA" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="20" cy="20" r="19" transform="rotate(90 20 20)" stroke="#BFB5AA"
                                stroke-width="2" />
                        </svg>
                    </span>
                </template>
            </Navigation>
        </template>
    </Carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

export default defineComponent({
    inject: ["mq"],
    name: "ImageCarousel",
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    methods: {
        klick: function (slide) {
            window.location.href = slide.redirect;
        },
    },
    computed: {
        smallScreen() {
            return this.mq.current == "sm";
        },
    },

    data: () => ({
        images: [
            {
                id: "1",
                sem: "1",
                url: "vonderzeit-2880px.webp",
                project: "Von der Zeit",
                text: "Magazin Feature | Editoral & Design",
                redirect: "/projekte/vonderzeit",
                alt: "Jenga-Tower aus Holz steht in der Natur",
            },
            {
                id: "2",
                sem: "4",
                url: "hdm-2880px.webp",
                project: "Prüfungsanmeldung",
                text: "Webapp | Konzeption & Umsetzung",
                redirect: "/projekte/pruefungsanmeldung",
                alt: "Close Up des Hochschule der Medien Gebäudes mit dem Namen 'Schnitz' bei Sonnenuntergang",
            },
            {
                id: "3",
                sem: "8",
                url: "schmetterlingseffekt-2880px.webp",
                project: "Der Schmetterlingseffekt",
                text: "Kommunikationskonzept | Konzeption & Design",
                redirect: "/projekte/schmetterlingseffekt",
                alt: "Innenraum und Fairteiler des Stuttgarter foodsharing Cafés 'Raupe Immersatt' am Hölderlinplatz",
            },

            {
                id: "8",
                sem: "7",
                url: "cinemar-2880px.webp",
                project: "CinemAR",
                text: "Leitsystem | Konzeption & Design",
                redirect: "/projekte/cinemar",
                alt: "Leerer Kinosaal",
            },
            // {
            //     id: "5",
            //     sem: "3",
            //     url: "drehmomente-2880px.jpg",
            //     project: "Drehmomente",
            //     text: "Interaktive Infografik | Design & Umsetzung in Processing",
            //     redirect: "22",
            // },
            {
                id: "6",
                sem: "4",
                url: "learningexperience-2880px.webp",
                project: "Learning Experience",
                text: "Usability Testing | Konzept & Umsetzung",
                redirect: "/projekte/learningexperience",
                alt: "Ein Mann und eine Frau benutzen die 'Learning Experience' Platform von Festo Didactic auf einem Tablet",
            },
        ],
    }),
});
</script>

<style>
.carousel__viewport {
    max-height: 80vh !important;
    height: 100%;
    display: block;
    overflow: hidden;
}

.carousel {
    height: 100%;
    padding: 100px 0;
}

.carousel__item {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
}

.carousel__item:hover {
    cursor: pointer;
}

.carousel__numbering {
    position: relative;
    text-align: right;
    top: 20px;
    right: -5%;
    font-family: "Albra";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    text-align: right;
    color: #bfb5aa;
    z-index: 50;
}

.carousel__heading {
    position: relative;
    text-align: left;
    bottom: 50px;
    left: -5%;
    font-family: "Albra";
    font-style: normal;
    font-weight: 400;
    font-size: 72px;
    color: #bfb5aa;
}

.carousel__text {
    position: relative;
    text-align: left;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #bfb5aa;
}

.carousel__image {
    max-width: 1140px;
    width: 60vw;
    max-height: 60vh;
    justify-self: center;
    overflow: hidden;
}

.carousel__image img {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    /* webkit-filter: brightness(80%); */
    /* filter: brightness(80%); */
}

.carousel__prev,
.carousel__next {
    margin: 0 4%;
}

@media only screen and (max-width: 960px) {
    .carousel__viewport {
        height: fit-content;
        overflow: visible;
    }

    .carousel {
        padding-top: 0;
        overflow: hidden;
    }

    .carousel__item {
        width: 80%;
    }

    .carousel__numbering {
        font-size: 16px;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    .carousel__heading {
        font-size: 24px;
        line-height: 140%;
        width: 100%;
        overflow-wrap: break-word;
        left: 0;
        right: 0;
        top: 10px;
    }

    .carousel__text {
        font-size: 14px;
        line-height: 140%;
        left: 0;
        right: 0;
    }

    .carousel__image {
        height: 60vh;
        width: auto;
        margin: 0;
    }

    .carousel__image img {
        width: 100%;
        object-fit: contain;
    }

    .carousel__prev,
    .carousel__next {
        margin: 0 8%;
    }
}
</style>
