<template>
    <div class="image-big-container" data-aos="fade-up" data-aos-offset="-200" @click="toggler = !toggler">
        <img class="image-one-big" :src="srcImage" :alt="altText" />
    </div>
    <!-- <div class="image-big-container" data-aos="fade-up" data-aos-offset="-200" @click="toggler = !toggler">
        <img class="image-one-big" :src="srcImage" :alt="altText" />
        <FsLightbox class="image-one-big" :alt="altText" :toggler="toggler" :sources="[srcImage]" />
    </div> -->
</template>

<script>
// import FsLightbox from "fslightbox-vue/v3";

export default {
    name: 'ImageBig',
    // components: { FsLightbox },
    data() {
        return {
            toggler: false
        }
    },
    props: {
        imageName: String,
        altText: String,
    },
    computed: {
        srcImage() {
            // eslint-disable-next-line no-undef
            return require("@/assets/images/" + this.imageName);
        }
    }
}
</script>

<style>
.image-big-container {
    height: 90vh;
    /* max-width: 1440px; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-bottom: 150px;
    /* object-fit: cover; */
}

.image-one-big {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: 90vh;
    object-fit: contain;
}

@media only screen and (max-width: 960px) {
    .image-big-container {
        height: 50vh;
        margin: 0;
    }
}

@media only screen and (min-width: 961px) {
    .image-big-container {
        width: 100%;
    }
}
</style>
